import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Labels } from "../../utils/constants";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";

export default function SBOMDetailModal({ data }) {
  function renderStatusCell(status) {
    let cellText = "";

    if (status === "success") {
      cellText = "Success";
    } else if (status === "warning") {
      cellText = "Warning";
    }

    function getBGColorForCell(cellText) {
      switch (cellText) {
        case "Success": {
          return "success.main";
        }
        case "Warning": {
          return "error.main";
        }
        default: {
          return "";
        }
      }
    }

    return (
      <Box
        sx={{
          width: "100px",
          textAlign: "center",
          paddingBlock: 0.75,
          color: "primary.main",
          borderRadius: "5px",
          backgroundColor: getBGColorForCell(cellText),
        }}
      >
        {cellText}
      </Box>
    );
  }

  function formatDataForUI() {
    let arr = [
      {
        label: Labels.UID,
        value: data?.id ?? "-",
      },
      {
        label: Labels.GIT_REPOSITORY_URL,
        value: data?.githubURL ?? "-",
      },
      {
        label: Labels.GIT_USERNAME,
        value: data?.githubUsername ?? "-",
      },
      {
        label: Labels.VULNERABILITY_PERCENTAGE,
        value: data?.vulnerabilityPercentage + "%" ?? "-",
      },
      {
        label: Labels.ALLOWED_BRANCHES,
        value: data?.allowedBranches ?? "-",
      },
      {
        label: Labels.STATUS,
        component: renderStatusCell(data.status),
      },
      {
        label: Labels.SUBMITTED_ON,
        value: data?.submissionDate
          ? formatDateToUserTimeZone(data.submissionDate)
          : "-",
      },
      {
        label: Labels.MESSAGE,
        value: data?.message ?? "-",
        fullWidth: data?.message?.length > 10 ? true : false,
      },
    ];

    return arr;
  }

  return (
    <Box
      className="custom-scroll-bar"
      sx={{
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 1,
        height: "calc(100% - 400px)",
        backgroundColor: "background.paper",
        minHeight: "200px",
        paddingInline: 5,
        paddingBlock: 3,
        boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        overflowY: "auto",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary.main" variant="h6">
            More Information
          </Typography>
        </Grid>
        <Grid container item spacing={6}>
          {formatDataForUI().map((item, index) => (
            <Grid
              item
              xs={12}
              {...(!item?.fullWidth && { md: 6, lg: 4 })}
              key={index}
            >
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 700, mb: 1 }}
                variant="subtitle2"
              >
                {item.label}
              </Typography>

              {item.component ? (
                item.component
              ) : (
                <Typography variant="subtitle1">{item.value}</Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
