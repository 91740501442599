export const UserRoles = {
  SystemAdmin: {
    label: "System Admin",
    id: 2,
  },
  ProjectManager: {
    label: "Project Manager",
    id: 3,
  },
  SecurityOfficer: {
    label: "Security Officer",
    id: 4,
  },
  Auditor: {
    label: "Auditor",
    id: 5,
  },
  Developer: {
    label: "Developer",
    id: 6,
  },
};

export const EmailTemplateType = {
  SigningRequestCreate: 1,
  SigningRequestApprove: 2,
  AssignSigningUserOrOffice: 3,
  CertificateExpiry: 4,
  SystemMaintanence: 5,
};

export const EmailConfigType = {
  SMTP: 1,
};

export const IdentityStoreType = {
  AD: 1,
  SSO: 2,
};

export const IdentityStoreADType = {
  AzureAD: 1,
  LocalAD: 2,
};

export const SigningRequestStatus = {
  ApprovePending: 0,
  Approved: 1,
  Signed: 2,
  Rejected: 3,
};

// Covert CSR to Import

export const CertificateTypes = {
  SelfSignedCertificate: 1,
  CertificateSigningRequest: 2,
  ImportCertificate: 3,
};

export const ApplicationManagementFormTypes = {
  Application: 1,
  Environment: 2,
  Team: 3,
  Policy: 4,
};

export const ArtificatTypes = [
  { id: 1, label: "exe" },
  { id: 2, label: "pdf" },
  { id: 3, label: "docx" },
  { id: 4, label: "doc" },
  { id: 5, label: "rar" },
  { id: 6, label: "jar" },
  { id: 7, label: "cat" },
  { id: 8, label: "sys" },
  { id: 9, label: "msi" },
  { id: 10, label: "cab" },
  { id: 11, label: "dll" },
];

export const Permissions = {
  ViewDashboard: 1,
  ViewKeysAndCertificates: 2,
  ViewApplicationManagement: 3,
  ViewSigningRequest: 4,
  ViewSystemSetup: 5,
  ViewSystemSetupEmail: 6,
  ViewSystemSetupUserManagement: 7,
  ViewSystemSetupUserPermissions: 8,
  ViewSystemSetupBackup: 9,
  ViewSystemSetupSigningProjects: 1.1,
  ViewSystemSetupSSLCertificate: 1.2,
  ViewSystemSetupSBOM: 1.3,
  ViewLogs: 31,
  ViewReports: 30,
  CreateSelfSignedCertificate: 20,
  GenerateCSR: 21,
  ImportCertificate: 22,
  ManageApplication: 10,
  ManageApplicationPolicy: 12,
  CreateEnvironment: 11,
  ManageSigningRequests: 13,
  ViewSigningTools: 14,
  ManageSSLCertificate: 18,
  DownloadReportSysLog: 32,
  DownloadLoggingSysLog: 34,
  ManageIdentityStore: 41,
  ManageBackup: 25,
  ManageEmailTemplate: 16,
  ManageEmailConfig: 15,
  CreateSSLCertificate: 23,
  RegisterUser: 26,
  CreateAPIKey: 24,
  ManageUser: 40,
  ManageSigningProjects: 27,
  ActivateLicense: 42,
  CreateTeam: 17,
  SBOMScan: 28,
};

export const PermissionDescription = {
  10: "This allows you to create an Application in the Application Management tab.",
  11: "This allows you to create a new Environment or use an existing one while creating/editing an Application.",
  12: "This lets you select the signing Policy for the Environment you created or assigned inside the Application.",
  13: "This allows you to either Approve or Reject the signing request for your specific application inside the Signing Requests tab.",
  14: "This allows you access to the Signing Tools tab and download the required tools.",
  15: "This allows you to setup new configurations for email responses.",
  16: "This allows you to create new template or edit existing templates.",
  17: "This allows you to create or manage Teams to perform code-signing operations",
  18: "This allows you to upload your own SSL or TLS certificate in CodeSign Secure server for the web portal",
  20: "This lets you create a Self-Signed code-signing certificate from our platform and download it into your local system.",
  21: "This lets you create code-signing CSR and download it in your local system.",
  22: "This allows you to upload your signed certificate from your PKI into our CodeSign Secure platform.",
  23: "This lets you generate a P12 SSL certificate for your client system.",
  24: "This lets you generate JWT Token/ API Key for running the APIs in your client system.",
  25: "This allows you to take a backup dump and restore this Backup on the server.",
  26: "This lets you add new user to the CodeSign Secure platform.",
  27: "This allows you to configure the changes in your Certificates table to choose the type of hash verification.",
  28: "This will allow you to check the vulnerability threat analysis of your code applications and commit to GitHub.",
  30: "This allows you to view and investigate all the reports in the Reports Tab.",
  31: "This allows you to access and investigate the logs in the Logging tab.",
  32: "This allows you to download the Syslog report of the Audit Trail in your local system.",
  34: "This allows you to download a Syslog file for the Logs into your local system.",
  40: "This allows you to grant roles to a user present in the platform.",
  41: "This allows you to add an Identity Store, which will be used in the Authentication and signing process.",
  42: "This lets you add your key inside the Activate License section to activate the license for specific days.",
  51: "This allows you to perform Sign and Verify operation via our platform",
  52: "This allows you to access and use our APIs in your local client system separately",
};
