import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";

const getSBOMTableDataService = createAsyncThunk(
  "sbom/getSBOMTableDataService",
  async (serviceConfig, { rejectWithValue }) => {
    try {
      let abortController = serviceConfig?.abortController;

      let request = await Api.get(
        URL.SBOM.GetSBOMData,
        abortController && {
          signal: abortController.signal,
        }
      );

      let result =
        request.data?.map((data, index) => ({
          id: data?._id ?? index,
          githubURL: data?.github_url ?? null,
          githubUsername: data?.github_username ?? null,
          vulnerabilityPercentage: data?.vulnerability_percentage ?? null,
          allowedBranches: data?.allowed_branch ?? null,
          status: data?.status ?? null,
          message: data?.message ?? null,
          submissionDate: data?.submission_date ?? null,
        })) ?? [];

      return result;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      let message = error.response?.data?.detail ?? null;
      let statusCode = error.response?.status ?? null;
      let statusText = error.response?.statusText ?? null;

      return rejectWithValue({ message, statusCode, statusText });
    }
  }
);

export default getSBOMTableDataService;
