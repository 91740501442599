import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../../../components/ui/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
import { selectSBOMDataByID } from "../../../features/sbom/SBOMSlice";
import getSBOMTableDataService from "../../../services/sbom/getSBOMTableData";
import { resetState } from "../../../features/sbom/SBOMSlice";
import SBOMDetailTable from "../../../features/sbom/SBOMDetailTable";
import SBOMDetailModal from "../../../features/sbom/SBOMDetailModal";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        pt: 3,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "125px",
          borderRadius: "10px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 1,
          height: "calc(100% - 380px)",
          borderRadius: "0px",
          boxShadow: "0px -2px 24px 6px rgba(0, 0, 0, 0.08)",
        }}
      />
    </Box>
  );
}

function SBOMDetail({ dispatch }) {
  const { id } = useParams();

  const { showBoundary } = useErrorBoundary();

  const navigate = useNavigate();

  const { isTableDataFetched, error, selectedSBOMData } = useSelector((state) =>
    selectSBOMDataByID(state, id)
  );

  useEffect(() => {
    if (!isTableDataFetched && !error) {
      dispatch(getSBOMTableDataService());
    }

    if (!selectedSBOMData && isTableDataFetched) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isTableDataFetched, error, selectedSBOMData]);

  if (error) {
    showBoundary(error);
  }

  if (!isTableDataFetched || !selectedSBOMData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box sx={{ width: 1, height: 1, pt: 3 }}>
      <SBOMDetailTable data={selectedSBOMData} />
      <SBOMDetailModal data={selectedSBOMData} />
    </Box>
  );
}

export default function ErrorComponentWrappedSBOMDetail() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorComponent}
      onReset={() => dispatch(resetState())}
    >
      <SBOMDetail dispatch={dispatch} />
    </ErrorBoundary>
  );
}
