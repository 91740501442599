import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Labels } from "../../utils/constants";
import Box from "@mui/material/Box";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";

export default function SBOMDetailTable({ data }) {
  function renderStatusCell(status) {
    let cellText = "";

    if (status === "success") {
      cellText = "Success";
    } else if (status === "warning") {
      cellText = "Warning";
    }

    function getBGColorForCell(cellText) {
      switch (cellText) {
        case "Success": {
          return "success.main";
        }
        case "Warning": {
          return "error.main";
        }
        default: {
          return "";
        }
      }
    }

    return (
      <Box
        sx={{
          width: "100px",
          textAlign: "center",
          paddingBlock: 0.75,
          color: "primary.main",
          borderRadius: "5px",
          backgroundColor: getBGColorForCell(cellText),
        }}
      >
        {cellText}
      </Box>
    );
  }

  return (
    <TableContainer
      className="custom-scroll-bar horizontal"
      component={Paper}
      sx={{
        boxShadow: "0px 4px 6px 1px rgba(0, 0, 0, 0.13)",
      }}
    >
      <Table size="medium">
        <TableHead
          sx={{
            th: {
              fontSize: "0.9rem",
              fontWeight: 700,
              color: "primary.main",
              borderColor: "#dbdadac2",
              whiteSpace: "nowrap",
            },
          }}
        >
          <TableRow className="custom-scroll-bar">
            <TableCell>{Labels.UID}</TableCell>
            <TableCell>{Labels.GIT_REPOSITORY_URL}</TableCell>
            <TableCell>{Labels.GIT_USERNAME}</TableCell>
            <TableCell>{Labels.VULNERABILITY_PERCENTAGE}</TableCell>
            <TableCell>{Labels.STATUS}</TableCell>
            <TableCell>{Labels.SUBMITTED_ON}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              td: {
                whiteSpace: "nowrap",
              },
            }}
          >
            <TableCell>{data.id}</TableCell>
            <TableCell>{data.githubURL}</TableCell>
            <TableCell>{data.githubUsername}</TableCell>
            <TableCell>{data.vulnerabilityPercentage + "%"}</TableCell>
            <TableCell>{renderStatusCell(data.status)}</TableCell>
            <TableCell>
              {formatDateToUserTimeZone(data.submissionDate)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
