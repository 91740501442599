import {
  FormType,
  FormElements,
  FormSize,
  FormLabels,
} from "../../utils/constants";
import * as Yup from "yup";
import getTriggerDataByKeyIDService from "../../services/keysAndCertificates/getTriggerDataByKeyID";
import updateKeyAndCertStatusService from "../../services/keysAndCertificates/updateKeyAndCertStatus";
import updateTriggerDataByKeyID from "../../services/keysAndCertificates/updateTriggerDataByKeyID";
import createSelfSignedCertificateService from "../../services/keysAndCertificates/createSelfSignedCertificate";
import createSigningRequestCertificateService from "../../services/keysAndCertificates/createSigningRequestCertificate";
import updateCertReproducibleBuildKeyUsage from "../../services/keysAndCertificates/updateCertReproducibleBuildKeyUsage";
import {
  createSigningProjectService,
  updateSigningProjectService,
} from "../../services/signingProjects/signingProjectsService";
import Api from "../../lib/axios/api";
import URL from "../../utils/url";
import createImportCertificateService from "../../services/keysAndCertificates/createImportCertificate";
import CreateCertificateFormHeader from "./components/CreateCertificateFormHeader";
import TriggerSetupFormFooter from "./components/TriggerSetupFormFooter";
import manageEmailTemplate from "../../services/email/manageEmailTemplate";
import manageEmailConfig from "../../services/email/manageEmailConfig";
import { IdentityStoreADType, UserRoles } from "../../utils/enums";
import manageIdentityAD from "../../services/identityStore/manageIdentityAD";
import manageIdentitySSO from "../../services/identityStore/manageIdentitySSO";
import getMFAStatusForRequestFormService from "../../services/signingRequests/getMFAStatusForRequestFormService";
import manageSigningRequestService from "../../services/signingRequests/manageSigningRequestService";
import addNewEnvironmentService from "../../services/applicationManagement/addNewEnvironmentService";
import getUsersFromAzure from "../../services/user/getUsersFromAzure";
import generateAPIKeyService from "../../services/user/generateAPIKeyService";
import generateP12Certificate from "../../services/user/generateP12Certificate";
import registerUserService from "../../services/user/registerUserService";
import updateUserRoleService from "../../services/user/updateUserRoleService";
import addNewTeamService from "../../services/applicationManagement/addNewTeamService";
import manageTeamService from "../../services/applicationManagement/manageTeamService";
import uploadSSLCertificate from "../../services/sslManagement/uploadCertificate";
import renewSSLCertificate from "../../services/sslManagement/renewSSLCertificate";
import generateCSR from "../../services/sslManagement/generateCSR";
import scanCodeService from "../../services/sbom/scanCodeService";

const formsConfig = {
  // For Trigger Setup of a certificate
  [FormType.TRIGGER_SETUP]: {
    title: FormLabels.TRIGGER_SETUP,
    width: FormSize.SMALL,
    shouldFetchInitialValues: true,
    initialValueFetchingService: getTriggerDataByKeyIDService,
    initialValue: {
      days: [],
      emails: [],
    },
    validationSchema: Yup.object().shape({
      days: Yup.array().of(
        Yup.object().shape({
          id: Yup.number(),
          label: Yup.string(),
          value: Yup.number(),
        })
      ),
      emails: Yup.array().of(Yup.string().email("Invalid email format")),
    }),
    formElements: [
      {
        placeholderText: "Type email here",
        label: FormLabels.ADDITIONAL_EMAILS,
        id: "emails",
        type: FormElements.MULTI_INPUT_FORM_ELEMENT,
      },
      {
        placeholderText: "Search days here",
        label: FormLabels.TRIGGER_DAYS,
        id: "days",
        type: FormElements.MULTI_SELECT_FORM_ELEMENT,
        optionIsString: false,
        dynamicOption: true,
      },
    ],
    FooterComponent: TriggerSetupFormFooter,
    buttonLabelForCancel: FormLabels.NO,
    buttonLabelForSubmit: FormLabels.YES,
    formSubmisionService: updateTriggerDataByKeyID,
  },
  // For changing certificate Status
  [FormType.EDIT_CERTIFICATE_STATUS]: {
    title: FormLabels.EDIT_STATUS,
    width: FormSize.EXTRA_SMALL,
    shouldFetchInitialValues: false,
    initialValue: {
      status: "",
    },
    validationSchema: Yup.object().shape({
      status: Yup.string().required("Select an option"),
    }),
    formElements: [
      {
        label: FormLabels.STATUS,
        id: "status",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: ["Active", "Inactive"],
      },
    ],
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.UPDATE,
    formSubmisionService: updateKeyAndCertStatusService,
  },
  // For creating self signed certificate
  [FormType.SELF_SIGNED_CERTIFICATE]: {
    title: FormLabels.CREATE_CERTIFICATE,
    width: FormSize.MEDIUM,
    shouldFetchInitialValues: false,
    initialValue: {
      certificateName: "",
      validFrom: null,
      validTo: null,
      certificateDescription: "",
      commonName: "",
      countryCode: "",
      organization: "",
      state: "",
      organizationUnit: "",
      locality: "",
      email: "",
      scope: "Application",
      keyAlgorithm: "",
      keyUsage: "None",
      keyRenewal: true,
    },
    validationSchema: Yup.object().shape({
      certificateName: Yup.string()
        .trim()
        .required("Certificate name is required")
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9_-]*$/, "Invalid certificate name"),

      validFrom: Yup.date().required("Valid from date is required"),

      validTo: Yup.date()
        .required("Valid to date is required")
        .min(
          Yup.ref("validFrom"),
          "Valid to date must be greater than valid from date"
        ),
      certificateDescription: Yup.string()
        .trim()
        .required("Certificate description is required"),

      commonName: Yup.string()
        .trim()
        .required("Common name is required")
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9_-]*$/, "Invalid common name"),

      countryCode: Yup.string()
        .trim()
        .required("Country code is required")
        .matches(/^[a-zA-Z]{2}$/, "Invalid country code"),

      organization: Yup.string()
        .trim()
        .required("Organization is required")
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/, "Invalid organization"),

      state: Yup.string()
        .trim()
        .required("State is required")
        .matches(/^[a-zA-Z\s]*$/, "Invalid state"),

      organizationUnit: Yup.string()
        .trim()
        .required("Organization unit is required")
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/, "Invalid organization unit"),

      locality: Yup.string()
        .trim()
        .required("Locality is required")
        .matches(/^[a-zA-Z\s]*$/, "Invalid locality"),

      email: Yup.string()
        .trim()
        .required("Email is required")
        .email("Invalid email format"),

      scope: Yup.string().trim().required("Scope is required"),

      keyAlgorithm: Yup.string().trim().required("Key algorithm is required"),

      keyUsage: Yup.string(),

      keyRenewal: Yup.boolean(),
    }),
    formElements: [
      {
        placeholderText: "Type Certificate Name",
        label: FormLabels.CERTIFICATE_NAME,
        id: "certificateName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Certificate Description",
        label: FormLabels.CERTIFICATE_DESCRIPTION,
        id: "certificateDescription",
        type: FormElements.TEXT_AREA_ELEMENT,
      },
      {
        type: FormElements.BREAK_LINE,
      },
      {
        placeholderText: "Day Month Year",
        label: FormLabels.VALID_FROM,
        id: "validFrom",
        type: FormElements.DATE_ELEMENT,
      },
      {
        placeholderText: "Day Month Year",
        label: FormLabels.VALID_TO,
        id: "validTo",
        type: FormElements.DATE_ELEMENT,
        minIDReference: "validFrom",
      },
      {
        type: FormElements.BREAK_LINE,
      },
      {
        placeholderText: "Type Organization",
        label: FormLabels.ORGANIZATION,
        id: "organization",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Organization Unit",
        label: FormLabels.ORGANIZATION_UNIT,
        id: "organizationUnit",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Common Name",
        label: FormLabels.COMMON_NAME,
        id: "commonName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        type: FormElements.BREAK_LINE,
      },
      {
        placeholderText: "Type Country Code",
        label: FormLabels.COUNTRY_CODE,
        id: "countryCode",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type State",
        label: FormLabels.STATE_PROVINCE,
        id: "state",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Locality",
        label: FormLabels.LOCALITY,
        id: "locality",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        type: FormElements.BREAK_LINE,
      },
      {
        placeholderText: "Type Email",
        label: FormLabels.EMAIL,
        id: "email",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Select Application",
        label: FormLabels.SCOPE,
        id: "scope",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: ["Application", "Business Unit", "Global"],
      },
      {
        placeholderText: "Select Key Algorithm",
        label: FormLabels.KEY_ALGORITHM,
        id: "keyAlgorithm",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: [
          "RSA 4096",
          "RSA 3072",
          "RSA 2048",
          "ECDSA 521",
          "ECDSA 384",
          "ECDSA 256",
        ],
      },
      {
        placeholderText: "Reproducible Build Key Usage",
        label: FormLabels.KEY_USAGE,
        id: "keyUsage",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: [
          "None",
          "Pre-Sign Hash Validation",
          "Post-Sign Hash Validation",
        ],
      },
      {
        label: FormLabels.KEY_RENEWAL,
        id: "keyRenewal",
        type: FormElements.CHEKBOX_ELEMENT,
      },
    ],
    HeaderComponent: CreateCertificateFormHeader,
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.DOWNLOAD,
    formSubmisionService: createSelfSignedCertificateService,
  },
  // For creating CSR
  [FormType.SIGNING_REQUEST_CERTIFICATE]: {
    title: FormLabels.CREATE_CERTIFICATE,
    width: FormSize.MEDIUM,
    shouldFetchInitialValues: false,
    initialValue: {
      certificateName: "",
      certificateDescription: "",
      commonName: "",
      countryCode: "",
      organization: "",
      state: "",
      organizationUnit: "",
      locality: "",
      email: "",
      scope: "Application",
      keyAlgorithm: "",
      keyUsage: "None",
    },
    validationSchema: Yup.object().shape({
      certificateName: Yup.string()
        .trim()
        .required("Certificate name is required")
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9_-]*$/, "Invalid certificate name"),

      certificateDescription: Yup.string()
        .trim()
        .required("Certificate description is required"),

      commonName: Yup.string()
        .trim()
        .required("Common name is required")
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9_-]*$/, "Invalid common name"),

      countryCode: Yup.string()
        .trim()
        .required("Country code is required")
        .matches(/^[a-zA-Z]{2}$/, "Invalid country code"),

      organization: Yup.string()
        .trim()
        .required("Organization is required")
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/, "Invalid organization"),

      state: Yup.string()
        .trim()
        .required("State is required")
        .matches(/^[a-zA-Z\s]*$/, "Invalid state"),

      organizationUnit: Yup.string()
        .trim()
        .required("Organization unit is required")
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/, "Invalid organization unit"),

      locality: Yup.string()
        .trim()
        .required("Locality is required")
        .matches(/^[a-zA-Z\s]*$/, "Invalid locality"),

      email: Yup.string()
        .trim()
        .required("Email is required")
        .email("Invalid email format"),

      scope: Yup.string().trim().required("Scope is required"),

      keyAlgorithm: Yup.string().trim().required("Key algorithm is required"),

      keyUsage: Yup.string(),
    }),
    formElements: [
      {
        placeholderText: "Type Certificate Name",
        label: FormLabels.CERTIFICATE_NAME,
        id: "certificateName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Certificate Description",
        label: FormLabels.CERTIFICATE_DESCRIPTION,
        id: "certificateDescription",
        type: FormElements.TEXT_AREA_ELEMENT,
      },
      {
        type: FormElements.BREAK_LINE,
      },
      {
        placeholderText: "Type Organization",
        label: FormLabels.ORGANIZATION,
        id: "organization",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Organization Unit",
        label: FormLabels.ORGANIZATION_UNIT,
        id: "organizationUnit",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Common Name",
        label: FormLabels.COMMON_NAME,
        id: "commonName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        type: FormElements.BREAK_LINE,
      },
      {
        placeholderText: "Type Country Code",
        label: FormLabels.COUNTRY_CODE,
        id: "countryCode",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type State",
        label: FormLabels.STATE_PROVINCE,
        id: "state",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Locality",
        label: FormLabels.LOCALITY,
        id: "locality",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        type: FormElements.BREAK_LINE,
      },
      {
        placeholderText: "Type Email",
        label: FormLabels.EMAIL,
        id: "email",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Select Application",
        label: FormLabels.SCOPE,
        id: "scope",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: ["Application", "Business Unit", "Global"],
      },
      {
        placeholderText: "Select Key Algorithm",
        label: FormLabels.KEY_ALGORITHM,
        id: "keyAlgorithm",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: [
          "RSA 4096",
          "RSA 3072",
          "RSA 2048",
          "ECDSA 521",
          "ECDSA 384",
          "ECDSA 256",
        ],
      },
      {
        placeholderText: "Reproducible Build Key Usage",
        label: FormLabels.KEY_USAGE,
        id: "keyUsage",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: [
          "None",
          "Pre-Sign Hash Validation",
          "Post-Sign Hash Validation",
        ],
      },
    ],
    HeaderComponent: CreateCertificateFormHeader,
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.DOWNLOAD,
    formSubmisionService: createSigningRequestCertificateService,
  },
  // For creating import certificate
  [FormType.IMPORT_CERTIFICATE]: {
    title: FormLabels.CREATE_CERTIFICATE,
    width: FormSize.MEDIUM,
    shouldFetchInitialValues: false,
    initialValue: {
      certificateName: "",
      certificateDescription: "",
      password: "",
      scope: "Application",
      certificateFile: null,
      keyUsage: "None",
    },
    validationSchema: Yup.object().shape({
      certificateName: Yup.string(),

      certificateDescription: Yup.string()
        .trim()
        .required("Certificate description is required"),

      password: Yup.string().trim(),

      scope: Yup.string().trim().required("Scope is required"),

      certificateFile: Yup.mixed()
        .required("Certificate file is required")
        .test(
          "fileSize",
          "Certificate size should be under 10 MB",
          async (file) => {
            if (!file) {
              return false;
            }

            const fileSize = file.size / 1024 / 1024;
            return fileSize <= 10;
          }
        )
        .test(
          "fileType",
          "Invalid file type. Only .cer and .crt files are allowed.",
          async function (file) {
            if (!file) {
              return false;
            }

            const fileExtension = file.name.split(".").pop().toLowerCase();

            return [".cer", ".crt"].includes("." + fileExtension);
          }
        ),
      // .test(
      //   "fileAvailability",
      //   "Certificate already exists in HSM. Please choose a different certificate.",
      //   async function (file) {
      //     if (!file) {
      //       return false;
      //     }
      //     const fileNameRegex = /^[a-zA-Z0-9][a-zA-Z0-9 _-]*$/;

      //     const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");

      //     if (!fileNameRegex.test(fileNameWithoutExtension)) {
      //       throw new Yup.ValidationError(
      //         "Invalid file name. Please use a different name.",
      //         file,
      //         "certificateFile"
      //       );
      //     }

      //     try {
      //       const request = await Api.get(
      //         URL.KeysAndCertificates.GetCertificateDetailByName +
      //           fileNameWithoutExtension +
      //           "/"
      //       );

      //       const certs = request.data;

      //       if (certs.length > 0) {
      //         return false;
      //       }

      //       return true;
      //     } catch (error) {
      //       console.log(error);
      //       return false;
      //     }
      //   }
      // ),

      keyUsage: Yup.string(),
    }),
    formElements: [
      {
        placeholderText: "Upload a Certificate",
        label: FormLabels.CERTIFICATE_NAME,
        isDiabled: true,
        id: "certificateName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Certificate Description",
        label: FormLabels.CERTIFICATE_DESCRIPTION,
        id: "certificateDescription",
        type: FormElements.TEXT_AREA_ELEMENT,
      },
      {
        placeholderText: "Upload Certificate here",
        label: FormLabels.CERTIFICATE_FILE,
        id: "certificateFile",
        type: FormElements.FILE_UPLOAD_ELEMENT,
        accept: ".crt, .csr",
        additionalOnChangeFunction: (setFieldValue, file) => {
          let fileNameWithoutExtention = file.name.replace(/\.[^/.]+$/, "");
          setFieldValue("certificateName", fileNameWithoutExtention);
        },
      },
      {
        placeholderText: "Type Password",
        label: FormLabels.PASSWORD,
        id: "password",
        type: FormElements.TEXT_FIELD_ELEMENT,
        inputType: "password",
      },
      {
        placeholderText: "Select Application",
        label: FormLabels.SCOPE,
        id: "scope",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: ["Application", "Business Unit", "Global"],
      },
      {
        placeholderText: "Reproducible Build Key Usage",
        label: FormLabels.KEY_USAGE,
        id: "keyUsage",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: [
          "None",
          "Pre-Sign Hash Validation",
          "Post-Sign Hash Validation",
        ],
      },
    ],
    HeaderComponent: CreateCertificateFormHeader,
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.ADD,
    formSubmisionService: createImportCertificateService,
  },
  // For changing certificate's key_usage field for hash validation
  [FormType.UPDATE_KEY_USAGE]: {
    title: FormLabels.UPDATE_KEY_USAGE,
    width: FormSize.EXTRA_SMALL,
    shouldFetchInitialValues: false,
    initialValue: {
      keyUsage: null,
    },
    formElements: [
      {
        placeholderText: "Reproducible Build Key Usage",
        label: FormLabels.KEY_USAGE,
        id: "keyUsage",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: [
          "None",
          "Pre-Sign Hash Validation",
          "Post-Sign Hash Validation",
        ],
      },
    ],
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.UPDATE,
    formSubmisionService: updateCertReproducibleBuildKeyUsage,
  },
  // For Email Template
  [FormType.EMAIL_TEMPLATE]: {
    title: FormLabels.EMAIL_TEMPLATE,
    width: FormSize.LARGE,
    shouldFetchInitialValues: false,
    initialValue: {
      emailType: null,
      emailSubject: "",
      emailContent: "",
    },
    validationSchema: Yup.object().shape({
      emailType: Yup.object()
        .shape({
          id: Yup.number(),
          label: Yup.string(),
          value: Yup.number(),
        })
        .required("Email Type is required"),
      emailSubject: Yup.string().trim().required("Email Subject is required"),
      emailContent: Yup.string().trim().required("Email Content is required"),
    }),
    formElements: [
      {
        placeholderText: "Select Email Type",
        label: FormLabels.TYPE,
        id: "emailType",
        type: FormElements.SELECT_FORM_ELEMENT,
        dynamicOption: true,
        optionIsString: false,
      },
      {
        placeholderText: "Type Email Subject",
        label: FormLabels.SUBJECT,
        id: "emailSubject",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
    ],
    textEditor: {
      label: FormLabels.CONTENT,
      id: "emailContent",
      textEditorType: "email",
    },
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.SUBMIT,
    formSubmisionService: manageEmailTemplate,
  },
  // For Email Config
  [FormType.EMAIL_CONFIG]: {
    title: FormLabels.EMAIL_CONFIG,
    width: FormSize.EXTRA_SMALL,
    shouldFetchInitialValues: false,
    initialValue: {
      configType: null,
      host: "",
      port: "",
      userName: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      configType: Yup.number().required("Specify type of configuration"),
      host: Yup.string()
        .required("Host is required")
        .matches(
          /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/,
          "Invalid host format"
        ),
      port: Yup.number()
        .required("Port is required")
        .integer("Port must be an integer")
        .positive("Port must be a positive number")
        .min(1, "Port must be greater than or equal to 1")
        .max(65535, "Port must be less than or equal to 65535"),
      userName: Yup.string()
        .required("User Name is required")
        .matches(/^[a-zA-Z0-9_.@-]*$/, "Invalid userName format"),
      password: Yup.string()
        .required("Password is required")
        .matches(/^\S*$/, "Invalid Password format"),
    }),
    formElements: [
      {
        placeholderText: "Type Host",
        label: FormLabels.HOST,
        id: "host",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type User Name",
        label: FormLabels.USERNAME,
        id: "userName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Port",
        label: FormLabels.PORT,
        id: "port",
        type: FormElements.TEXT_FIELD_ELEMENT,
        inputType: "number",
      },
      {
        placeholderText: "Type Password",
        label: FormLabels.PASSWORD,
        id: "password",
        type: FormElements.TEXT_FIELD_ELEMENT,
        inputType: "password",
      },
    ],
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.SUBMIT,
    formSubmisionService: manageEmailConfig,
  },
  // For AD Identity Store
  [FormType.AD_IDENTITY]: {
    title: FormLabels.IDENTITY_AD,
    width: FormSize.MEDIUM,
    shouldFetchInitialValues: false,
    initialValue: {
      ADType: { id: 1, label: "Azure AD", value: IdentityStoreADType.AzureAD },
      clientID: "",
      secretKey: "",
      callbackURL: "",
      connectionURL: "",
    },
    validationSchema: Yup.object().shape({
      ADType: Yup.object()
        .shape({
          id: Yup.number(),
          label: Yup.string(),
          value: Yup.number(),
        })
        .required("Select AD Type"),

      clientID: Yup.string()
        .required("Client ID is required")
        .matches(/^[^\s]+$/, "Invalid characters in Client ID"),
      // .matches(/^[a-zA-Z0-9-]+$/, "Invalid characters in Client ID"),
      // .min(8, "Client ID must be at least 8 characters")
      // .max(50, "Client ID cannot exceed 50 characters"),

      secretKey: Yup.string().when(["ADType"], ([ADType], schema) => {
        if (ADType.value === IdentityStoreADType.AzureAD) {
          return (
            schema
              .matches(/^[^\s]+$/, "Invalid characters in Secret key")
              // .matches(/^[a-zA-Z0-9-]+$/, "Invalid characters in Secret key") // Aplha numeric + hyphens
              // .min(16, "Secret key must be at least 16 characters")
              // .max(64, "Secret key cannot exceed 64 characters")
              .required("Secret key is required")
          );
        }
        return schema;
      }),

      callbackURL: Yup.string().when("ADType", (ADType, schema) => {
        if (ADType[0].value === IdentityStoreADType.AzureAD) {
          return schema
            .required("Callback URL is required")
            .url("Invalid URL format for Callback URL");
        }
        return schema;
      }),

      connectionURL: Yup.string()
        .required("Connection URL is required")
        .url("Invalid URL format for Connection URL"),
    }),
    formElements: [
      {
        placeholderText: "Select AD Type",
        label: FormLabels.AD_TYPE,
        id: "ADType",
        type: FormElements.SELECT_FORM_ELEMENT,
        optionIsString: false,
        options: [
          { id: 1, label: "Azure AD", value: IdentityStoreADType.AzureAD },
          { id: 2, label: "Local AD", value: IdentityStoreADType.LocalAD },
        ],
      },
      {
        placeholderText: "Type Client ID",
        label: FormLabels.CLIENT_ID,
        id: "clientID",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Secret Key",
        label: FormLabels.SECRET_KEY,
        id: "secretKey",
        type: FormElements.TEXT_FIELD_ELEMENT,
        inputType: "password",
        renderLogic: (formik) => {
          let type = formik.values["ADType"];
          if (type.value === IdentityStoreADType.AzureAD) return true;
          return false;
        },
      },
      {
        placeholderText: "Type Callback URL",
        label: FormLabels.CALLBACK_URL,
        id: "callbackURL",
        type: FormElements.TEXT_FIELD_ELEMENT,
        renderLogic: (formik) => {
          let type = formik.values["ADType"];
          if (type.value === IdentityStoreADType.AzureAD) return true;
          return false;
        },
      },
      {
        placeholderText: "Type Connection URL",
        label: FormLabels.CONNECTION_URL,
        id: "connectionURL",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
    ],
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.SUBMIT,
    formSubmisionService: manageIdentityAD,
  },
  // For SSO Identity Store
  [FormType.SSO_IDENTITY]: {
    title: FormLabels.IDENTITY_SSO,
    width: FormSize.MEDIUM,
    shouldFetchInitialValues: false,
    initialValue: {
      clientID: "",
      secretKey: "",
      callbackURL: "",
      connectionURL: "",
      logoutURL: "",
    },
    validationSchema: Yup.object().shape({
      clientID: Yup.string()
        .required("Client ID is required")
        .matches(/^[^\s]+$/, "Invalid characters in Client ID"),
      // .matches(/^[a-zA-Z0-9-]+$/, "Invalid characters in Client ID")
      // .min(8, "Client ID must be at least 8 characters")
      // .max(50, "Client ID cannot exceed 50 characters"),
      secretKey: Yup.string()
        .required("Secret key is required")
        .matches(/^[^\s]+$/, "Invalid characters in Secret key"),
      // .matches(/^[a-zA-Z0-9_-]+$/, "Invalid characters in Secret key") // Aplha numeric + underscore + hyphens
      // .min(16, "Secret key must be at least 16 characters")
      // .max(64, "Secret key cannot exceed 64 characters"),
      callbackURL: Yup.string()
        .required("Callback URL is required")
        .url("Invalid URL format for Callback URL"),
      connectionURL: Yup.string()
        .required("Connection URL is required")
        .url("Invalid URL format for Connection URL"),
      logoutURL: Yup.string()
        .required("Logout URL is required")
        .url("Invalid URL format for Logout URL"),
    }),
    formElements: [
      {
        placeholderText: "Type Client ID",
        label: FormLabels.CLIENT_ID,
        id: "clientID",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Secret Key",
        label: FormLabels.SECRET_KEY,
        id: "secretKey",
        type: FormElements.TEXT_FIELD_ELEMENT,
        inputType: "password",
      },
      {
        placeholderText: "Type Callback URL",
        label: FormLabels.CALLBACK_URL,
        id: "callbackURL",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Connection URL",
        label: FormLabels.CONNECTION_URL,
        id: "connectionURL",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Logout URL",
        label: FormLabels.LOGOUT_URL,
        id: "logoutURL",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
    ],
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.SUBMIT,
    formSubmisionService: manageIdentitySSO,
  },
  // For creating Automated Signing Project
  [FormType.ADD_SIGNING_PROJECT]: {
    title: FormLabels.SIGNING_PROJECT,
    width: FormSize.MEDIUM,
    shouldFetchInitialValues: false,
    initialValue: {
      projectName: "",
      sourceCodeManagementType: "git",
      gitRepositoryURL: "",
      gitAllowedBranches: "",
      gitUserName: "",
      gitPassword: "",

      environmentalVariables: "",
      buildCommand: "",
      buildFilePath: "",
      buildSignerType: "SignTool with KSP",

      buildServerType: "jenkins",
      jenkinsProjectURL: "",
      jenkinsUserName: "",
      jenkinsPassword: "",
      emailRecipients: "",
    },
    validationSchema: Yup.object().shape({
      projectName: Yup.string().trim().required("Project Name is required"),
      sourceCodeManagementType: Yup.string()
        .trim()
        .required("Source Code Management Type is required"),
      gitRepositoryURL: Yup.string()
        .trim()
        .required("Git Repository URL is required")
        .url("Invalid URL Format"),
      gitUserName: Yup.string().trim().required("Git Username is required"),
      gitPassword: Yup.string().trim().required("Git Password is required"),
      buildCommand: Yup.string().trim().required("Build Command is required"),
      buildFilePath: Yup.string()
        .trim()
        .required("Build File Path is required"),
      buildSignerType: Yup.string()
        .trim()
        .required("Build Signer Type is required"),

      buildServerType: Yup.string()
        .trim()
        .required("Build Server Type is required"),
      jenkinsProjectURL: Yup.string()
        .trim()
        .required("Jenkins Project URL is required")
        .url("Invalid URL format"),
      jenkinsUserName: Yup.string()
        .trim()
        .required("Jenkins Username is required"),
      jenkinsPassword: Yup.string()
        .trim()
        .required("Jenkins Password is required"),
      emailRecipients: Yup.string()
        .trim()
        .required("Email is required")
        .email("Invalid email format"),
    }),
    formElements: [
      {
        placeholderText: "Type Project Name",
        label: FormLabels.PROJECT_NAME,
        id: "projectName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        type: FormElements.BREAK_LINE,
      },
      {
        placeholderText: "Type Source Code Management Type",
        label: FormLabels.SOURCE_CODE_MANAGEMENT_TYPE,
        id: "sourceCodeManagementType",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: ["git"],
      },
      {
        placeholderText: "Type Git Repository URL",
        label: FormLabels.GIT_REPOSITORY_URL,
        id: "gitRepositoryURL",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Git Username",
        label: FormLabels.GIT_USERNAME,
        id: "gitUserName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Git Password",
        label: FormLabels.GIT_PASSWORD,
        id: "gitPassword",
        type: FormElements.TEXT_FIELD_ELEMENT,
        inputType: "password",
      },
      {
        placeholderText:
          "[Optional] Type Allowed Branches \norigin/master \norigin/release/*",
        label: FormLabels.GIT_ALLOWED_BRANCHES,
        id: "gitAllowedBranches",
        type: FormElements.TEXT_AREA_ELEMENT,
      },
      {
        type: FormElements.BREAK_LINE,
      },
      {
        placeholderText:
          "[Optional] Type Environmental Variables \nJAVA_VERSION=1.2.3 \nPORT=5432",
        label: FormLabels.ENVIRONMENTAL_VARIABLES,
        id: "environmentalVariables",
        type: FormElements.TEXT_AREA_ELEMENT,
      },
      {
        placeholderText: "Type Build Command",
        label: FormLabels.BUILD_COMMAND,
        id: "buildCommand",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Build File Path",
        label: FormLabels.BUILD_FILE_PATH,
        id: "buildFilePath",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Build Signer Type",
        label: FormLabels.BUILD_SIGNER_TYPE,
        id: "buildSignerType",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: ["SignTool with KSP", "JarSigner with KSP"],
      },
      {
        type: FormElements.BREAK_LINE,
      },
      {
        placeholderText: "Build Server Type",
        label: FormLabels.BUILD_SERVER_TYPE,
        id: "buildServerType",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: ["jenkins"],
      },
      {
        placeholderText: "Type Jenkins Project URL",
        label: FormLabels.JENKINS_PROJECT_URL,
        id: "jenkinsProjectURL",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Jenkins Username",
        label: FormLabels.JENKINS_USERNAME,
        id: "jenkinsUserName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Jenkins Password",
        label: FormLabels.JENKINS_PASSWORD,
        id: "jenkinsPassword",
        type: FormElements.TEXT_FIELD_ELEMENT,
        inputType: "password",
      },
      {
        type: FormElements.BREAK_LINE,
      },
      {
        placeholderText: "Type Email Recipient",
        label: FormLabels.EMAIL_RECIPIENTS,
        id: "emailRecipients",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
    ],
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.SUBMIT,
    formSubmisionService: createSigningProjectService,
  },
  [FormType.EDIT_SIGNING_PROJECT]: {
    title: FormLabels.SIGNING_PROJECT,
    width: FormSize.MEDIUM,
    shouldFetchInitialValues: false,
    initialValue: {
      projectName: "",
      sourceCodeManagementType: "",
      gitRepositoryURL: "",
      gitAllowedBranches: "",
      gitUserName: "",
      gitPassword: "",

      buildCommand: "",
      buildFilePath: "",
      buildSignerType: "SignTool with KSP",
      environmentalVariables: "",
      buildServerType: "",
      jenkinsProjectURL: "",
      jenkinsUserName: "",
      jenkinsPassword: "",
      emailRecipients: "",
    },
    validationSchema: Yup.object().shape({
      projectName: Yup.string().trim().required("Project Name is required"),
      sourceCodeManagementType: Yup.string()
        .trim()
        .required("Source Code Management Type is required"),
      gitRepositoryURL: Yup.string()
        .trim()
        .required("Git Repository URL is required")
        .url("Invalid URL Format"),
      gitUserName: Yup.string().trim().required("Git Username is required"),
      gitPassword: Yup.string(),
      buildCommand: Yup.string().trim().required("Build Command is required"),
      buildFilePath: Yup.string()
        .trim()
        .required("Build File Path is required"),
      buildSignerType: Yup.string()
        .trim()
        .required("Build Signer Type is required"),

      buildServerType: Yup.string()
        .trim()
        .required("Build Server Type is required"),
      jenkinsProjectURL: Yup.string()
        .trim()
        .required("Jenkins Project URL is required")
        .url("Invalid URL format"),
      jenkinsUserName: Yup.string()
        .trim()
        .required("Jenkins Username is required"),
      jenkinsPassword: Yup.string(),
      emailRecipients: Yup.string()
        .trim()
        .required("Email is required")
        .email("Invalid email format"),
    }),
    formElements: [
      {
        placeholderText: "Type Project Name",
        label: FormLabels.PROJECT_NAME,
        id: "projectName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        type: FormElements.BREAK_LINE,
      },
      {
        placeholderText: "Type Source Code Management Type",
        label: FormLabels.SOURCE_CODE_MANAGEMENT_TYPE,
        id: "sourceCodeManagementType",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: ["git"],
      },
      {
        placeholderText: "Type Git Repository URL",
        label: FormLabels.GIT_REPOSITORY_URL,
        id: "gitRepositoryURL",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Git Username",
        label: FormLabels.GIT_USERNAME,
        id: "gitUserName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Git Password if you wish to update it",
        label: FormLabels.GIT_PASSWORD,
        id: "gitPassword",
        type: FormElements.TEXT_FIELD_ELEMENT,
        inputType: "password",
      },
      {
        placeholderText:
          "[Optional] Type Allowed Branches \norigin/master \norigin/release/*",
        label: FormLabels.GIT_ALLOWED_BRANCHES,
        id: "gitAllowedBranches",
        type: FormElements.TEXT_AREA_ELEMENT,
      },
      {
        type: FormElements.BREAK_LINE,
      },

      {
        placeholderText:
          "[Optional] Type Environmental Variables \nJAVA_VERSION=1.2.3 \nPORT=5432",
        label: FormLabels.ENVIRONMENTAL_VARIABLES,
        id: "environmentalVariables",
        type: FormElements.TEXT_AREA_ELEMENT,
      },
      {
        placeholderText: "Type Build Command",
        label: FormLabels.BUILD_COMMAND,
        id: "buildCommand",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Build File Path",
        label: FormLabels.BUILD_FILE_PATH,
        id: "buildFilePath",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Build Signer Type",
        label: FormLabels.BUILD_SIGNER_TYPE,
        id: "buildSignerType",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: ["SignTool with KSP", "JarSigner with KSP"],
      },
      {
        type: FormElements.BREAK_LINE,
      },

      {
        placeholderText: "Build Server Type",
        label: FormLabels.BUILD_SERVER_TYPE,
        id: "buildServerType",
        type: FormElements.SELECT_FORM_ELEMENT,
        options: ["jenkins"],
      },
      {
        placeholderText: "Type Jenkins Project URL",
        label: FormLabels.JENKINS_PROJECT_URL,
        id: "jenkinsProjectURL",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Jenkins Username",
        label: FormLabels.JENKINS_USERNAME,
        id: "jenkinsUserName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Jenkins Password if you wish to update it",
        label: FormLabels.JENKINS_PASSWORD,
        id: "jenkinsPassword",
        type: FormElements.TEXT_FIELD_ELEMENT,
        inputType: "password",
      },
      {
        type: FormElements.BREAK_LINE,
      },
      {
        placeholderText: "Type Email Recipient",
        label: FormLabels.EMAIL_RECIPIENTS,
        id: "emailRecipients",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
    ],
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.SUBMIT,
    formSubmisionService: updateSigningProjectService,
  },
  // For Signing Request
  [FormType.SIGNING_REQUEST]: {
    title: FormLabels.SIGNING_REQUEST,
    dynamicTitleCreator: (store) => {
      let isApprovalRequestMethod =
        store.getState().signingRequests.isRequestApprovalForm;

      if (isApprovalRequestMethod) {
        return FormLabels.SIGNING_REQUEST_APPROVAL;
      } else {
        return FormLabels.SIGNING_REQUEST_REJECTION;
      }
    },
    width: FormSize.EXTRA_SMALL,
    shouldFetchInitialValues: true,
    initialValueFetchingService: getMFAStatusForRequestFormService,
    initialValue: {
      needMFA: null,
      otp: "",
      comment: "",
    },
    validationSchema: Yup.object().shape({
      needMFA: Yup.boolean(),
      otp: Yup.string()
        .nullable()
        .when(["needMFA"], ([needMFA], schema) => {
          if (needMFA === true) {
            return schema
              .matches(/^\d{6}$/, "OTP must be a 6-digit number")
              .required("OTP is required");
          }
          return schema;
        }),
      comment: Yup.string().trim().required("Comment is required"),
    }),
    formElements: [
      {
        label: FormLabels.OTP,
        id: "otp",
        type: FormElements.OTP_INPUT_ELEMENT,
        renderLogic: (formik) => {
          let isMFANeeded = formik.values["needMFA"];
          if (isMFANeeded === true) return true;
          return false;
        },
        resendOTP: async function ({ jobID }) {
          await Api.get(URL.SigningRequests.SendOTP + jobID);
        },
        timeLimit: 300, //In seconds
      },
      {
        placeholderText: "Type Comment",
        label: FormLabels.COMMENT,
        id: "comment",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
    ],
    buttonLabelForCancel: FormLabels.NO,
    buttonLabelForSubmit: FormLabels.YES,
    formSubmisionService: manageSigningRequestService,
  },
  // For Creating New Environment in Application Managemenet
  [FormType.CREATE_ENVIRONMENT]: {
    title: FormLabels.CREATE_ENVIRONMENT,
    width: FormSize.EXTRA_SMALL,
    shouldFetchInitialValues: false,
    initialValue: {
      environmentName: "",
    },
    validationSchema: Yup.object().shape({
      environmentName: Yup.string()
        .trim()
        .required("Environment Name is Required"),
    }),
    formElements: [
      {
        label: FormLabels.ENVIRONMENT_NAME,
        id: "environmentName",
        type: FormElements.TEXT_FIELD_ELEMENT,
        placeholderText: "Type Environment Name",
      },
    ],
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.ADD,
    formSubmisionService: addNewEnvironmentService,
  },
  // For registering New User in System Setup / User
  [FormType.REGISTER_USER]: {
    title: FormLabels.REGISTER_USER,
    width: FormSize.MEDIUM,
    shouldFetchInitialValues: false,
    initialValue: {
      email: null,
      firstName: "",
      lastName: "",
      password: "",
      userRoles: [],
    },
    validationSchema: Yup.object().shape({
      email: Yup.object().required("Email is Required"),
      firstName: Yup.string()
        .required("First Name is required")
        .min(2, "First Name should be at least 2 characters"),
      lastName: Yup.string()
        .required("Last Name is required")
        .min(2, "Last Name should be at least 2 characters"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^\S{8,}$/,
          "Password should have at least 8 chars and no whitespace"
        ),
      userRoles: Yup.array().min(1, "Specify at least 1 role"),
    }),
    formElements: [
      {
        label: FormLabels.EMAIL,
        id: "email",
        type: FormElements.ASYNC_MULTI_SELECT_ELEMENT,
        placeholderText: "Type Email",
        optionFetchingFunction: getUsersFromAzure,
        multipleValues: false,
      },
      {
        placeholderText: "Select User Roles",
        label: FormLabels.USER_ROLES,
        id: "userRoles",
        type: FormElements.MULTI_SELECT_FORM_ELEMENT,
        optionIsString: false,
        options: [
          {
            id: UserRoles.SystemAdmin.id,
            label: UserRoles.SystemAdmin.label,
            value: UserRoles.SystemAdmin,
          },
          {
            id: UserRoles.ProjectManager.id,
            label: UserRoles.ProjectManager.label,
            value: UserRoles.ProjectManager,
          },
          {
            id: UserRoles.SecurityOfficer.id,
            label: UserRoles.SecurityOfficer.label,
            value: UserRoles.SecurityOfficer,
          },
          {
            id: UserRoles.Auditor.id,
            label: UserRoles.Auditor.label,
            value: UserRoles.Auditor,
          },
          {
            id: UserRoles.Developer.id,
            label: UserRoles.Developer.label,
            value: UserRoles.Developer,
          },
        ],
      },
      {
        label: FormLabels.FIRST_NAME,
        id: "firstName",
        type: FormElements.TEXT_FIELD_ELEMENT,
        placeholderText: "Type First Name",
      },
      {
        label: FormLabels.LAST_NAME,
        id: "lastName",
        type: FormElements.TEXT_FIELD_ELEMENT,
        placeholderText: "Type Last Name",
      },
      {
        placeholderText: "Type password",
        label: FormLabels.PASSWORD,
        id: "password",
        type: FormElements.TEXT_FIELD_ELEMENT,
        inputType: "password",
      },
    ],
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.REGISTER,
    formSubmisionService: registerUserService,
  },
  // For modifying user roles
  [FormType.MODIFY_USER_ROLES]: {
    title: FormLabels.MODIFY_USER_ROLES,
    width: FormSize.EXTRA_SMALL,
    shouldFetchInitialValues: false,
    initialValue: {
      userRoles: [],
    },
    validationSchema: Yup.object().shape({
      userRoles: Yup.array().min(1, "Specify at least 1 role"),
    }),
    formElements: [
      {
        placeholderText: "Select User Roles",
        label: FormLabels.USER_ROLES,
        id: "userRoles",
        type: FormElements.MULTI_SELECT_FORM_ELEMENT,
        optionIsString: false,
        options: [
          {
            id: UserRoles.SystemAdmin.id,
            label: UserRoles.SystemAdmin.label,
            value: UserRoles.SystemAdmin,
          },
          {
            id: UserRoles.ProjectManager.id,
            label: UserRoles.ProjectManager.label,
            value: UserRoles.ProjectManager,
          },
          {
            id: UserRoles.SecurityOfficer.id,
            label: UserRoles.SecurityOfficer.label,
            value: UserRoles.SecurityOfficer,
          },
          {
            id: UserRoles.Auditor.id,
            label: UserRoles.Auditor.label,
            value: UserRoles.Auditor,
          },
          {
            id: UserRoles.Developer.id,
            label: UserRoles.Developer.label,
            value: UserRoles.Developer,
          },
        ],
      },
    ],
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.SUBMIT,
    formSubmisionService: updateUserRoleService,
  },
  // For generating API key
  [FormType.GENERATE_API_KEY]: {
    title: FormLabels.GENERATE_API_KEY,
    width: FormSize.EXTRA_SMALL,
    shouldFetchInitialValues: false,
    initialValue: {
      keyName: null,
      expiryDate: null,
    },
    validationSchema: Yup.object().shape({
      keyName: Yup.string().trim().required("Key Name is required"),
      expiryDate: Yup.object().required("Expiry Date is required"),
    }),
    formElements: [
      {
        placeholderText: "Enter Token Name",
        label: FormLabels.API_KEY_NAME,
        id: "keyName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Select Days",
        label: FormLabels.EXPIRY_DATE,
        id: "expiryDate",
        type: FormElements.SELECT_FORM_ELEMENT,
        optionIsString: false,
        options: [
          {
            id: 1,
            label: "30 Days",
            value: 30,
          },
          { id: 2, label: "60 Days", value: 60 },
          { id: 3, label: "90 Days", value: 90 },
        ],
      },
    ],
    buttonLabelForCancel: FormLabels.CLOSE,
    buttonLabelForSubmit: FormLabels.GENERATE,
    formSubmisionService: generateAPIKeyService,
  },
  // For Generating SSL Authentication Certificate
  [FormType.GENERATE_AUTHENTICATION_CERTIFICATE]: {
    title: FormLabels.GENERATE_AUTHENTICATION_CERTIFICATE,
    width: FormSize.EXTRA_SMALL,
    shouldFetchInitialValues: false,
    initialValue: {
      userName: "",
      certificateName: "",
      validTill: null,
    },
    validationSchema: Yup.object().shape({
      userName: Yup.string().trim().required("User Name is required"),
      certificateName: Yup.string()
        .trim()
        .required("Certificate Name is required"),
      validTill: Yup.date()
        .min(
          new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
          "Select a date that is at least 24 hours ahead"
        )
        .required("Valid Till is required"),
    }),
    formElements: [
      {
        placeholderText: "Enter User Name",
        label: FormLabels.USERNAME,
        id: "userName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Enter Certificate Name",
        label: FormLabels.CERTIFICATE_NAME,
        id: "certificateName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Day Month Year",
        label: FormLabels.VALID_TILL,
        id: "validTill",
        type: FormElements.DATE_ELEMENT,
      },
    ],
    buttonLabelForCancel: FormLabels.CLOSE,
    buttonLabelForSubmit: FormLabels.GENERATE,
    formSubmisionService: generateP12Certificate,
  },
  // For Creating Team
  [FormType.CREATE_TEAM]: {
    title: FormLabels.CREATE_TEAM,
    width: FormSize.EXTRA_SMALL,
    shouldFetchInitialValues: false,
    initialValue: {
      teamName: "",
      members: [],
    },
    validationSchema: Yup.object().shape({
      teamName: Yup.string().trim().required("Team Name is required"),
      members: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string(),
            id: Yup.number(),
            name: Yup.string(),
          })
        )
        .min(1, "Specify at least one member"),
    }),
    formElements: [
      {
        placeholderText: "Enter Team Name",
        label: FormLabels.TEAM_NAME,
        id: "teamName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Search member name here",
        label: FormLabels.Members,
        id: "members",
        type: FormElements.MULTI_SELECT_FORM_ELEMENT,
        optionIsString: false,
        dynamicOption: true,
      },
    ],
    buttonLabelForCancel: FormLabels.CLOSE,
    buttonLabelForSubmit: FormLabels.CREATE,
    formSubmisionService: addNewTeamService,
  },
  // For Editing Team
  [FormType.EDIT_TEAM]: {
    title: FormLabels.EDIT_TEAM,
    width: FormSize.EXTRA_SMALL,
    shouldFetchInitialValues: false,
    initialValue: {
      name: "",
      members: [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required("Team Name is required"),
      members: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string(),
            id: Yup.number(),
            name: Yup.string(),
          })
        )
        .min(1, "Specify at least one member"),
    }),
    formElements: [
      {
        placeholderText: "Enter Team Name",
        label: FormLabels.TEAM_NAME,
        id: "name",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Search member name here",
        label: FormLabels.Members,
        id: "members",
        type: FormElements.MULTI_SELECT_FORM_ELEMENT,
        optionIsString: false,
        dynamicOption: true,
      },
    ],
    buttonLabelForCancel: FormLabels.CLOSE,
    buttonLabelForSubmit: FormLabels.UPDATE,
    formSubmisionService: manageTeamService,
  },
  // For Importing SSL/TLS Certificate
  [FormType.IMPORT_SSL_CERTIFICATE]: {
    title: FormLabels.IMPORT_SSL_TLS_CERTIFICATE,
    width: FormSize.SMALL,
    shouldFetchInitialValues: false,
    initialValue: {
      certificate: null,
      certificateFullChain: null,
    },
    validationSchema: Yup.object().shape({
      certificate: Yup.mixed()
        .required("Certificate is required")
        .test(
          "fileSize",
          "Certificate file size should be under 10 MB",
          async (file) => {
            if (!file) {
              return false;
            }
            const fileSize = file.size / 1024 / 1024;
            return fileSize <= 10;
          }
        )
        .test(
          "fileType",
          "Invalid file type. Only .cer, .crt and .pem files are allowed.",
          async function (file) {
            if (!file) {
              return false;
            }

            const fileExtension = file.name.split(".").pop().toLowerCase();

            return [".pem", ".cer", ".crt"].includes("." + fileExtension);
          }
        ),
      certificateFullChain: Yup.mixed()
        .required("Certificate Fullchain is required")
        .test(
          "fileSize",
          "CertificateFullChain file size should be under 10 MB",
          async (file) => {
            if (!file) {
              return false;
            }
            const fileSize = file.size / 1024 / 1024;
            return fileSize <= 10;
          }
        )
        .test(
          "fileType",
          "Invalid file type. Only .cer, .crt and .pem files are allowed.",
          async function (file) {
            if (!file) {
              return false;
            }

            const fileExtension = file.name.split(".").pop().toLowerCase();

            return [".pem", ".cer", ".crt"].includes("." + fileExtension);
          }
        ),
    }),
    formElements: [
      {
        placeholderText: "Import Certificate here",
        label: FormLabels.CERTIFICATE,
        id: "certificate",
        type: FormElements.FILE_UPLOAD_ELEMENT,
        accept: ".crt, .csr, .pem",
      },
      {
        placeholderText: "Import Certificate Fullchain here",
        label: FormLabels.CERTIFICATE_FULLCHAIN,
        id: "certificateFullChain",
        type: FormElements.FILE_UPLOAD_ELEMENT,
        accept: ".crt, .csr, .pem",
      },
    ],
    buttonLabelForCancel: FormLabels.CLOSE,
    buttonLabelForSubmit: FormLabels.ADD,
    formSubmisionService: uploadSSLCertificate,
  },
  // For Generating CSR For SSL Cert
  [FormType.Generate_CSR_FOR_SSL]: {
    title: FormLabels.GENERATE_CSR,
    width: FormSize.MEDIUM,
    shouldFetchInitialValues: false,
    initialValue: {
      commonName: "",
      organization: "",
      organizationUnit: "",
      countryCode: "",
      state: "",
      locality: "",
    },
    validationSchema: Yup.object().shape({
      commonName: Yup.string()
        .trim()
        .required("Common name is required")
        .matches(
          /^(?!-)[A-Za-z0-9-]+([-.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/,
          "Invalid common name"
        ),

      organization: Yup.string()
        .trim()
        .required("Organization is required")
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/, "Invalid organization"),

      organizationUnit: Yup.string()
        .trim()
        .required("Organization unit is required")
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/, "Invalid organization unit"),

      countryCode: Yup.string()
        .trim()
        .required("Country code is required")
        .matches(/^[a-zA-Z]{2}$/, "Invalid country code"),

      state: Yup.string()
        .trim()
        .required("State is required")
        .matches(/^[a-zA-Z\s]*$/, "Invalid state"),

      locality: Yup.string()
        .trim()
        .required("Locality is required")
        .matches(/^[a-zA-Z\s]*$/, "Invalid locality"),
    }),
    formElements: [
      {
        placeholderText: "Type Common Name",
        label: FormLabels.COMMON_NAME,
        id: "commonName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Organization",
        label: FormLabels.ORGANIZATION,
        id: "organization",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Organization Unit",
        label: FormLabels.ORGANIZATION_UNIT,
        id: "organizationUnit",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Country Code",
        label: FormLabels.COUNTRY_CODE,
        id: "countryCode",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type State",
        label: FormLabels.STATE_PROVINCE,
        id: "state",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Locality",
        label: FormLabels.LOCALITY,
        id: "locality",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
    ],
    buttonLabelForCancel: FormLabels.CLOSE,
    buttonLabelForSubmit: FormLabels.DOWNLOAD,
    formSubmisionService: generateCSR,
  },
  // For Scanning Code
  [FormType.SBOM_CODE_SCAN]: {
    title: FormLabels.SCAN_CODE,
    width: FormSize.MEDIUM,
    shouldFetchInitialValues: false,
    initialValue: {
      gitUserName: "",
      gitToken: "",
      gitEmail: "",
      gitRepositoryURL: "",
      gitAllowedBranch: "",
      vulnerabilityPercentage: null,
      sourceCode: "",
    },
    validationSchema: Yup.object().shape({
      gitUserName: Yup.string().trim().required("Git Username is required"),
      gitToken: Yup.string().trim().required("Git Token is required"),
      gitAllowedBranch: Yup.string()
        .trim()
        .required("Git Allowed Branche is required"),
      gitEmail: Yup.string()
        .trim()
        .required("Git Email is required")
        .email("Enter a valid email"),
      gitRepositoryURL: Yup.string()
        .trim()
        .required("Git Repository URL is required")
        .url("Invalid URL Format"),
      vulnerabilityPercentage: Yup.number()
        .min(0, "The Percantage can't go below 0")
        .max(100, "The Percentage can't go above 100")
        .required("Vulnerability Percentage is required"),
      sourceCode: Yup.mixed()
        .required("Source Code is required")
        .test(
          "fileSize",
          "COde file size should be under 30 MB",
          async (file) => {
            if (!file) {
              return false;
            }
            const fileSize = file.size / 1024 / 1024;
            return fileSize <= 30;
          }
        )
        .test(
          "fileType",
          "Invalid file type. Only .zip file is allowed.",
          async function (file) {
            if (!file) {
              return false;
            }

            const fileExtension = file.name.split(".").pop().toLowerCase();

            return [".zip"].includes("." + fileExtension);
          }
        ),
    }),
    formElements: [
      {
        placeholderText: "Type Git Repository URL",
        label: FormLabels.GIT_REPOSITORY_URL,
        id: "gitRepositoryURL",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Git Username",
        label: FormLabels.GIT_USERNAME,
        id: "gitUserName",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Git Email",
        label: FormLabels.GIT_EMAIL,
        id: "gitEmail",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Type Vulnerability Percentage",
        label: FormLabels.VULNERABILITY_PERCENTAGE,
        id: "vulnerabilityPercentage",
        type: FormElements.TEXT_FIELD_ELEMENT,
        inputType: "number",
      },
      {
        placeholderText: "Type Allowed Branch, eg:- master",
        label: FormLabels.GIT_ALLOWED_BRANCH,
        id: "gitAllowedBranch",
        type: FormElements.TEXT_FIELD_ELEMENT,
      },
      {
        placeholderText: "Upload Source Code here",
        label: FormLabels.SOURCE_CODE,
        id: "sourceCode",
        type: FormElements.FILE_UPLOAD_ELEMENT,
        accept: ".zip",
      },
      {
        placeholderText: "Paste Git Token here",
        label: FormLabels.GIT_TOKEN,
        id: "gitToken",
        type: FormElements.TEXT_AREA_ELEMENT,
      },
    ],
    buttonLabelForCancel: FormLabels.CANCEL,
    buttonLabelForSubmit: FormLabels.SUBMIT,
    formSubmisionService: scanCodeService,
  },
};

export default formsConfig;
