import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Layout from "./pages/Layout";
import KeysAndCertificatesIndex from "./pages/Keys&Certificates/KeysAndCertificatesIndex";
import KeyAndCertificateDetail from "./pages/Keys&Certificates/KeyAndCertificateDetail";
import SigningTools from "./pages/SigningTools";
import Logs from "./pages/Logs/LogsIndex";
import LogsDetail from "./pages/Logs/LogsDetail";
import ReportsIndex from "./pages/Reports/ReportsIndex";
import ReportsDetail from "./pages/Reports/ReportsDetail";
import SystemSetupIndex from "./pages/SystemSetup";
import SystemSetupBackup from "./pages/SystemSetup/backup";
import SystemSetupEmail from "./pages/SystemSetup/Email/EmailIndex";
import EmailConfig from "./pages/SystemSetup/Email/EmailConfig";
import EmailTemplate from "./pages/SystemSetup/Email/EmailTemplate";
import EmailConfigDetail from "./pages/SystemSetup/Email/EmailConfigDetail";
import IdentityStoreIndex from "./pages/SystemSetup/IdentityStore/IdentityStoreIndex";
import IdentityStoreDetail from "./pages/SystemSetup/IdentityStore/IdentityStoreDetail";
import NotFoundComponent from "./components/ui/NotFoundComponent";
import SigningRequestIndex from "./pages/SigningRequest/SigningRequestIndex";
import SigningRequestDetail from "./pages/SigningRequest/SigningRequestDetail";
import ApplicationManagementIndex from "./pages/ApplicationManagement/ApplicationManagementIndex";
import ApplicationManagementDetail from "./pages/ApplicationManagement/ApplicationManagementDetail";
import ManageApplication from "./pages/ApplicationManagement/ManageApplication";
import AddApplication from "./pages/ApplicationManagement/AddApplication";
import SystemSetupUser from "./pages/SystemSetup/User/UserIndex";
import UserManagement from "./pages/SystemSetup/User/UserManagement";
import UserPermission from "./pages/SystemSetup/User/UserPermission";
import UserManagementDetail from "./pages/SystemSetup/User/UserManagementDetail";
import checkUserPermission from "./utils/checkUserPermission";
import { Permissions } from "./utils/enums";
import Notification from "./utils/notificationConfiguration";
import { NotificationLabels } from "./utils/constants";
import SigningProjectsIndex from "./pages/SystemSetup/SigningProjects/SigningProjectsIndex";
import SigningProjectDetails from "./pages/SystemSetup/SigningProjects/SigningProjectDetails";
import { store } from "./lib/redux/store";
import LicensePage from "./pages/License";
import SSLManagement from "./pages/SystemSetup/ManageSSLCertificate/SSLIndex";
import SSLDetail from "./pages/SystemSetup/ManageSSLCertificate/SSLDetail";
import SBOMIndex from "./pages/SystemSetup/SBOM/SBOMIndex";
import SBOMDetail from "./pages/SystemSetup/SBOM/SBOMDetail";

export const ProtectedRoute = ({ children, permissionID }) => {
  let license = store.getState().license.licenseInfo;

  if (license === null) {
    return <Navigate to="/license" />;
  } else if (!license.status) {
    return <Navigate to="/license?action=expired" />;
  }

  if (!checkUserPermission(permissionID)) {
    Notification.warning(NotificationLabels.NOT_AUTHORIZED);
    return <Navigate to="/" />;
  }

  return children;
};

function setRedirectURLForSystemSetupIndexPage() {
  if (checkUserPermission(Permissions.ViewSystemSetup)) {
    return "identity-store";
  } else if (checkUserPermission(Permissions.ViewSystemSetupEmail)) {
    return "email";
  } else if (checkUserPermission(Permissions.ViewSystemSetupBackup)) {
    return "backup";
  } else if (
    checkUserPermission(Permissions.ViewSystemSetupUserManagement) ||
    checkUserPermission(Permissions.ViewSystemSetupUserPermissions)
  ) {
    return "user";
  } else if (checkUserPermission(Permissions.ViewSystemSetupSSLCertificate)) {
    return "ssl-tls-management";
  } else if (checkUserPermission(Permissions.ViewSystemSetupSBOM)) {
    return "sbom";
  } else {
    return "/";
  }
}

export default function Router() {
  return (
    <Routes>
      <Route path="license" element={<LicensePage />} />
      <Route element={<Layout />}>
        <Route
          path="/"
          element={
            <ProtectedRoute permissionID={Permissions.ViewDashboard}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/keys-and-certificates">
          <Route
            index
            element={
              <ProtectedRoute
                permissionID={Permissions.ViewKeysAndCertificates}
              >
                <KeysAndCertificatesIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="detail/:keyID"
            element={
              <ProtectedRoute
                permissionID={Permissions.ViewKeysAndCertificates}
              >
                <KeyAndCertificateDetail />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/signing-request">
          <Route
            index
            element={
              <ProtectedRoute permissionID={Permissions.ViewSigningRequest}>
                <SigningRequestIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="detail/:requestID"
            element={
              <ProtectedRoute permissionID={Permissions.ManageSigningRequests}>
                <SigningRequestDetail />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/logs">
          <Route
            index
            element={
              <ProtectedRoute permissionID={Permissions.ViewLogs}>
                <Logs />
              </ProtectedRoute>
            }
          />
          <Route
            path="detail/"
            element={
              <ProtectedRoute permissionID={Permissions.ViewLogs}>
                <LogsDetail />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path="/signing-tools"
          element={
            <ProtectedRoute permissionID={Permissions.ViewSigningTools}>
              <SigningTools />
            </ProtectedRoute>
          }
        />
        <Route path="/reports">
          <Route
            index
            element={
              <ProtectedRoute permissionID={Permissions.ViewReports}>
                <ReportsIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="detail/"
            element={
              <ProtectedRoute permissionID={Permissions.ViewReports}>
                <ReportsDetail />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/application-management">
          <Route
            index
            element={
              <ProtectedRoute
                permissionID={Permissions.ViewApplicationManagement}
              >
                <ApplicationManagementIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="new"
            element={
              <ProtectedRoute permissionID={Permissions.ManageApplication}>
                <AddApplication />
              </ProtectedRoute>
            }
          />
          <Route
            path=":applicationID"
            element={
              <ProtectedRoute permissionID={Permissions.ManageApplication}>
                <ManageApplication />
              </ProtectedRoute>
            }
          />
          <Route
            path="detail/:applicationID"
            element={
              <ProtectedRoute
                permissionID={Permissions.ViewApplicationManagement}
              >
                <ApplicationManagementDetail />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/system-setup" element={<SystemSetupIndex />}>
          <Route
            index
            element={<Navigate to={setRedirectURLForSystemSetupIndexPage()} />}
          />
          <Route
            path="backup"
            element={
              <ProtectedRoute permissionID={Permissions.ViewSystemSetupBackup}>
                <SystemSetupBackup />
              </ProtectedRoute>
            }
          />
          <Route
            path="user"
            element={
              <ProtectedRoute
                permissionID={Permissions.ViewSystemSetupUserManagement}
              >
                <SystemSetupUser />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to="management" />} />
            <Route
              path="management"
              element={
                <ProtectedRoute
                  permissionID={Permissions.ViewSystemSetupUserManagement}
                >
                  <UserManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="management/detail/:userID"
              element={
                <ProtectedRoute
                  permissionID={Permissions.ViewSystemSetupUserManagement}
                >
                  <UserManagementDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="permissions"
              element={
                <ProtectedRoute
                  permissionID={Permissions.ViewSystemSetupUserPermissions}
                >
                  <UserPermission />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="identity-store">
            <Route
              index
              element={
                <ProtectedRoute permissionID={Permissions.ViewSystemSetup}>
                  <IdentityStoreIndex />
                </ProtectedRoute>
              }
            />
            <Route
              path="detail/:identityID"
              element={
                <ProtectedRoute permissionID={Permissions.ViewSystemSetup}>
                  <IdentityStoreDetail />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="ssl-tls-management">
            <Route
              index
              element={
                <ProtectedRoute
                  permissionID={Permissions.ViewSystemSetupSSLCertificate}
                >
                  <SSLManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="detail/:certificateID"
              element={
                <ProtectedRoute
                  permissionID={Permissions.ViewSystemSetupSSLCertificate}
                >
                  <SSLDetail />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="signing-projects">
            <Route
              index
              element={
                <ProtectedRoute
                  permissionID={Permissions.ViewSystemSetupSigningProjects}
                >
                  <SigningProjectsIndex />
                </ProtectedRoute>
              }
            />
            <Route
              path="detail/:projectID"
              element={
                <ProtectedRoute
                  permissionID={Permissions.ViewSystemSetupSigningProjects}
                >
                  <SigningProjectDetails />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="sbom">
            <Route
              index
              element={
                <ProtectedRoute permissionID={Permissions.ViewSystemSetupSBOM}>
                  <SBOMIndex />
                </ProtectedRoute>
              }
            />
            <Route
              path="detail/:id"
              element={
                <ProtectedRoute permissionID={Permissions.ViewSystemSetupSBOM}>
                  <SBOMDetail />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="email" element={<SystemSetupEmail />}>
            <Route index element={<Navigate to="template" />} />
            <Route
              path="template"
              element={
                <ProtectedRoute permissionID={Permissions.ViewSystemSetupEmail}>
                  <EmailTemplate />
                </ProtectedRoute>
              }
            />
            <Route
              path="config"
              element={
                <ProtectedRoute permissionID={Permissions.ViewSystemSetupEmail}>
                  <EmailConfig />
                </ProtectedRoute>
              }
            />
            <Route
              path="config/detail/:configID"
              element={
                <ProtectedRoute permissionID={Permissions.ViewSystemSetupEmail}>
                  <EmailConfigDetail />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>

        {/* Catch All */}
        <Route path="*" element={<NotFoundComponent />} />
      </Route>
    </Routes>
  );
}
