import Api from "../../lib/axios/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import URL from "../../utils/url";
import { IdentityStoreType, Permissions, UserRoles } from "../../utils/enums";
import { store } from "../../lib/redux/store";
import { initiateLogout } from "../../features/auth/authSlice";

export const getUserDataService = createAsyncThunk(
  "auth/getUserDataService",
  async ({ IDToken }) => {
    try {
      const requestBody = {
        code: IDToken,
        identityType: IdentityStoreType.AD,
      };

      let userDataReq = Api.post(URL.Auth.UserData, requestBody);
      let productVersionReq = Api.get(URL.Auth.ProductVersion);

      const [userDataResponse, productVersionResponse] = await Promise.all([
        userDataReq,
        productVersionReq,
      ]);

      let userRoles =
        userDataResponse.data?.Role?.map((role) => {
          switch (role.Id) {
            case 2: {
              return UserRoles.SystemAdmin;
            }
            case 3: {
              return UserRoles.ProjectManager;
            }
            case 4: {
              return UserRoles.SecurityOfficer;
            }
            case 5: {
              return UserRoles.Auditor;
            }
            case 6: {
              return UserRoles.Developer;
            }
            default: {
              return null;
            }
          }
        }) ?? [];

      if (userRoles.length === 0) {
        setTimeout(() => {
          store.dispatch(initiateLogout());
        }, 5000);
        throw Error(
          "The user you are trying to access could not be found. Will be logged out in 5 seconds"
        );
      }

      if (
        userRoles.length === 1 &&
        userRoles[0].id === UserRoles.Developer.id
      ) {
        throw Error("You dont have necessary permissions!");
      }

      let userPermissionReqArr = userRoles.map((role) =>
        Api.get(URL.User.GetPermissions + role.id + "/")
      );

      let userPermissionRes = await Promise.all([...userPermissionReqArr]);

      let userPermissions = {};

      userPermissionRes.forEach((response, index) => {
        if (userRoles[index].id === UserRoles.SystemAdmin.id) {
          // Assigning Page View Permissions based on Role
          userPermissions[Permissions.ViewDashboard] = {
            status: true,
          };
          userPermissions[Permissions.ViewKeysAndCertificates] = {
            status: true,
          };
          userPermissions[Permissions.ViewApplicationManagement] = {
            status: true,
          };
          userPermissions[Permissions.ViewSigningRequest] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetup] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetupEmail] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetupUserManagement] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetupUserPermissions] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetupBackup] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetupSigningProjects] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetupSSLCertificate] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetupSBOM] = {
            status: true,
          };

          response.data?.forEach((permission) => {
            if (userPermissions[permission.pid] === undefined) {
              userPermissions[permission.pid] = {
                status: permission.status,
                label: permission.pname,
              };
            } else if (userPermissions[permission.pid].status === false) {
              userPermissions[permission.pid] = {
                status: permission.status,
                label: permission.pname,
              };
            }
          });
        }

        if (userRoles[index].id === UserRoles.ProjectManager.id) {
          userPermissions[Permissions.ViewDashboard] = {
            status: true,
          };
          userPermissions[Permissions.ViewApplicationManagement] = {
            status: true,
          };
          userPermissions[Permissions.ViewSigningRequest] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetupEmail] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetupSBOM] = {
            status: true,
          };

          response.data?.forEach((permission) => {
            if (permission.pid >= 10 && permission.pid <= 17) {
              if (userPermissions[permission.pid] === undefined) {
                userPermissions[permission.pid] = {
                  status: permission.status,
                  label: permission.pname,
                };
              } else if (userPermissions[permission.pid].status === false) {
                userPermissions[permission.pid] = {
                  status: permission.status,
                  label: permission.pname,
                };
              }
            }
          });
        }

        if (userRoles[index].id === UserRoles.SecurityOfficer.id) {
          userPermissions[Permissions.ViewDashboard] = {
            status: true,
          };
          userPermissions[Permissions.ViewKeysAndCertificates] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetupUserManagement] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetupBackup] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetupSigningProjects] = {
            status: true,
          };
          userPermissions[Permissions.ViewSystemSetupSBOM] = {
            status: true,
          };

          response.data?.forEach((permission) => {
            console.log(permission);
            if (permission.pid >= 20 && permission.pid <= 28) {
              if (userPermissions[permission.pid] === undefined) {
                userPermissions[permission.pid] = {
                  status: permission.status,
                  label: permission.pname,
                };
              } else if (userPermissions[permission.pid].status === false) {
                userPermissions[permission.pid] = {
                  status: permission.status,
                  label: permission.pname,
                };
              }
            }
          });
        }

        if (userRoles[index].id === UserRoles.Auditor.id) {
          userPermissions[Permissions.ViewDashboard] = {
            status: true,
          };

          userPermissions[Permissions.ViewSystemSetupSBOM] = {
            status: true,
          };

          response.data?.forEach((permission) => {
            if (permission.pid >= 30 && permission.pid <= 34) {
              if (userPermissions[permission.pid] === undefined) {
                userPermissions[permission.pid] = {
                  status: permission.status,
                  label: permission.pname,
                };
              } else if (userPermissions[permission.pid].status === false) {
                userPermissions[permission.pid] = {
                  status: permission.status,
                  label: permission.pname,
                };
              }
            }
          });
        }

        if (userRoles[index].id === UserRoles.Developer.id) {
          response.data?.forEach((permission) => {
            if (permission.pid >= 51 && permission.pid <= 52) {
              if (userPermissions[permission.pid] === undefined) {
                userPermissions[permission.pid] = {
                  status: permission.status,
                  label: permission.pname,
                };
              } else if (userPermissions[permission.pid].status === false) {
                userPermissions[permission.pid] = {
                  status: permission.status,
                  label: permission.pname,
                };
              }
            }
          });
        }
      });

      let response = {
        ...userDataResponse.data,
        ...productVersionResponse.data,
        userRoles,
        userPermissions,
      };

      localStorage.setItem("userID", userDataResponse.data.Id);

      return response;
    } catch (error) {
      throw error;
    }
  }
);
