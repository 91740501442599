export const BackendSettings = {
  BaseURL: "/",
  // BaseURL: "https://lunacodesignsecure.encryptionconsulting.com/",
  // BaseURL: "https://devcodesignsecure.encryptionconsulting.com/",
  // BaseURL: "https://codesignsecure.encryptionconsulting.com/",
};

export const Status = {
  Idle: "idle",
  Succeeded: "succeeded",
  Failed: "failed",
  Loading: "loading",
};

export const Labels = {
  SAN: "San",
  CREATED_AT: "Created At",
  EXPIRES_AT: "Expires At",
  DOMAIN: "Domain",
  DELETE: "Delete",
  ACTIVE: "Active",
  IN_ACTIVE: "Inactive",
  LOCK: "Lock",
  UNLOCK: "Unlock",
  LOCKED: "Locked",
  UnLocked: "Unlocked",
  KEY_NAME: "Key Name",
  UID: "UID",
  ALGORITHM: "Algorithm",
  SIZE: "Size",
  STATUS: "Status",
  VALID_TILL: "Valid Till",
  VALID_FROM: "Valid From",
  ACTION: "Actions",
  APPLICATION_NAME: "Application Name",
  APPLICATION: "Application",
  CERTIFICATE_NAME: "Certificate Name",
  COMMON_NAME: "Common Name",
  COUNTRY_CODE: "Country Code",
  EMAIL: "Email",
  ORGANIZATION: "Organization",
  STATE: "State",
  STATE_PROVINCE: "State/Province",
  TRIGGER_DAYS: "Trigger Days",
  SETUP_TRIGGER: "Setup Trigger",
  DEACTIVATE: "Deactivate",
  EDIT_STATUS: "Edit Status",
  DATE: "Date",
  EVENT: "Event",
  SOURCE: "Source",
  JOB_ID: "Job ID",
  SUBMITTED_BY: "Submitted By",
  SUBMITTED_ON: "Submitted On",
  APPROVED_ON: "Approved On",
  APPROVER: "Approver",
  CREATED_BY: "Created By",
  CREATED_DATE: "Created Date",
  EXPIRY_DATE: "Expiry Date",
  EVENT_TIME: "Event Time",
  CATEGORY: "Category",
  APPLICATION_ID: "Application ID",
  MESSAGE: "Message",
  LOCATION: "Location",
  VALIDITY_PERIOD: "Validity Period",
  CREATED_USER: "Created User",
  SCOPE: "Scope",
  USERNAME: "User Name",
  IP_ADDRESS: "IP Address",
  USER: "User",
  LEVEL: "Level",
  KEY_ALGORITHM: "Key Algorithm",
  ORGANIZATION_NAME: "Organization Name",
  ORGANIZATION_UNIT: "Organization Unit",
  DESCRIPTION: "Description",
  BACKUP_NAME: "Backup Name",
  DOWNLOAD: "Download",
  ARCHIVE: "Archive",
  RESTORE: "Restore",
  NO: "No",
  YES: "Yes",
  TYPE: "Type",
  SUBJECT: "Subject",
  EDIT: "Edit",
  NAME: "Name",
  HOST: "Host",
  PORT: "PORT",
  CLIENT_ID: "Client ID",
  CALLBACK_URL: "Callback URL",
  CONNECTION_URL: "Connection URL",
  IDENTITY_STORE_TYPE: "Identity Store Type",
  REQUEST_ID: "Request ID",
  ENVIRONMENT_NAME: "Environment Name",
  APPROVE: "Approve",
  REJECT: "Reject",
  COMMENT: "Comment",
  APPLICATION_OWNERS: "Application Owners",
  APPLICATION_DESCRIPTION: "Application Description",
  BUSINESS_UNIT: "Business Unit",
  REPOSITORY_URL: "Repository URL",
  ARTIFACT_TYPES: "Artifact Types",
  CERTIFICATE_TYPE: "Certificate Type",
  CERTIFICATE_ORGANIZATION: "Certificate Organization",
  SIGNING_USERS: "Signing Users",
  NEED_MFA: "Need MFA",
  APPROVER_TYPE: "Approver Type",
  APPROVERS: "Approvers",
  CERTIFICATE_DATA: "Certificate Data",
  KEY_ALIAS: "Key Alias",
  CLIENT_IP: "Client IP",
  APPROVER_DETAILS: "Approver Details",
  TEAM_DETAILS: "Team Details",
  PROJECT_NAME: "Project Name",
  UPDATE_KEY_USAGE: "Update Key Usage",
  KEY_USAGE: "Key Usage",
  SOURCE_CODE_MANAGEMENT_TYPE: "Source Code Management",
  GIT_REPOSITORY_URL: "Git Repository URL",
  GIT_USERNAME: "Git Username",
  GIT_ALLOWED_BRANCHES: "Git Allowed Branches [Optional]",
  BUILD_SERVER_TYPE: "Build Server",
  JENKINS_PROJECT_URL: "Jenkins Project URL",
  ADD_SIGNING_PROJECT: "Add Signing Project",
  EDIT_SIGNING_PROJECT: "Edit Project",
  DELETE_SIGNING_PROJECT: "Delete Project",
  ROLES: "Roles",
  ROLE: "Role",
  EDIT_PERMISSION: "Edit Permission",
  UPLOAD: "Upload",
  CANCEL: "Cancel",
  SCAN_CODE: "Scan Code",
  VULNERABILITY_PERCENTAGE: "Vulnerability",
  GITHUB_USERNAME: "Git Username",
  ALLOWED_BRANCHES: "Allowed Branches",
};

export const FormType = {
  NONE: "none",
  TRIGGER_SETUP: "triggerSetup",
  EDIT_CERTIFICATE_STATUS: "editCertificateStatus",
  SELF_SIGNED_CERTIFICATE: "selfSignedCertificate",
  SIGNING_REQUEST_CERTIFICATE: "signingRequestCertificate",
  IMPORT_CERTIFICATE: "importCertificate",
  UPDATE_KEY_USAGE: "updateCertReproducibleBuildKeyUsage",
  EMAIL_TEMPLATE: "emailTemplate",
  EMAIL_CONFIG: "emailConfig",
  AD_IDENTITY: "ADIdentity",
  SSO_IDENTITY: "SSOIdentity",
  SIGNING_REQUEST: "signingRequest",
  CREATE_ENVIRONMENT: "createEnvironment",
  REGISTER_USER: "registerUser",
  GENERATE_API_KEY: "generateAPIKay",
  GENERATE_AUTHENTICATION_CERTIFICATE: "GenerateAuthenticationCertificate",
  MODIFY_USER_ROLES: "modifyUserRoles",
  CREATE_TEAM: "createTeam",
  EDIT_TEAM: "editTeam",
  ADD_SIGNING_PROJECT: "addSigningProject",
  EDIT_SIGNING_PROJECT: "editSigningProject",
  IMPORT_SSL_CERTIFICATE: "importSSLCertificate",
  RENEW_SSL_CERTIFICATE: "renewSSLCertificate",
  Generate_CSR_FOR_SSL: "generateCSRForSSL",
  SBOM_CODE_SCAN: "sbomCodeScan",
};

export const FormElements = {
  ASYNC_MULTI_SELECT_ELEMENT: "asyncMultiSelectElement",
  MULTI_INPUT_FORM_ELEMENT: "multiInputFormElement",
  MULTI_SELECT_FORM_ELEMENT: "multiSelectFormElement",
  SELECT_FORM_ELEMENT: "selectFormElement",
  TEXT_FIELD_ELEMENT: "textFieldElement",
  PASSWORD_FIELD_ELEMENT: "passwordFieldElement",
  DATE_ELEMENT: "dateElement",
  FILE_UPLOAD_ELEMENT: "fileUploadElement",
  TEXT_EDITOR_ELEMENT: "textEditor",
  OTP_INPUT_ELEMENT: "otpInputElement",
  TEXT_AREA_ELEMENT: "textAreaElement",
  CHEKBOX_ELEMENT: "checkBoxElement",
  BREAK_LINE: "breakLine",
};

export const FormSize = {
  EXTRA_SMALL: "xs",
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
  EXTRA_LARGE: "xl",
};

export const FormLabels = {
  GIT_EMAIL: "Git Email",
  VULNERABILITY_PERCENTAGE: "Vulnerability Percentage",
  SOURCE_CODE: "Source Code",
  GENERATE_CSR: "Generate CSR",
  CERTIFICATE_PRIVATE_KEY: "Certificate Private Key",
  RENEW_EXISTING_CERTIFICATE: "Renewing Existing Certificate",
  CERTIFICATE_FULLCHAIN: "Certificate Fullchain",
  CERTIFICATE: "Certificate",
  DOMAIN_NAME: "Domain Name",
  IMPORT_SSL_TLS_CERTIFICATE: "Import SSL/TLS Certificate",
  EDIT_STATUS: "Edit Status",
  ADDITIONAL_EMAILS: "Additional Emails",
  TRIGGER_SETUP: "Trigger Setup",
  TRIGGER_DAYS: "Trigger Days",
  CANCEL: "Cancel",
  SUBMIT: "Submit",
  REGISTER: "Register",
  ADD: "Add",
  DOWNLOAD: "Download",
  YES: "Yes",
  NO: "No",
  UPDATE: "Update",
  STATUS: "Status",
  CREATE_CERTIFICATE: "Create Certificate",
  CERTIFICATE_NAME: "Certificate Name",
  VALID_FROM: "Valid From",
  VALID_TO: "Valid To",
  CERTIFICATE_DESCRIPTION: "Certificate Description",
  COMMON_NAME: "Common Name",
  COUNTRY_CODE: "Country Code",
  ORGANIZATION: "Organization",
  ORGANIZATION_UNIT: "Organization Unit",
  STATE_PROVINCE: "State or Province",
  LOCALITY: "Locality",
  EMAIL: "Email",
  SCOPE: "Scope",
  KEY_ALGORITHM: "Key Algorithm",
  PASSWORD: "Password",
  CERTIFICATE_FILE: "Certificate File",
  UPDATE_KEY_USAGE: "Edit Key Usage",
  CREATE_TEAM: "Create Team",
  EMAIL_TEMPLATE: "Email Template",
  TYPE: "Type",
  SUBJECT: "Subject",
  CONTENT: "Content",
  EMAIL_CONFIG: "Email Config",
  HOST: "Host",
  PORT: "Port",
  USERNAME: "User Name",
  IDENTITY_AD: "Identity AD",
  AD_TYPE: "AD Type",
  CLIENT_ID: "Client ID",
  CONNECTION_URL: "Connection URL",
  CALLBACK_URL: "Callback URL",
  SECRET_KEY: "Secret Key",
  IDENTITY_SSO: "Identity SSO",
  LOGOUT_URL: "Logout URL",
  SIGNING_REQUEST: "Signing Request",
  COMMENT: "Comment",
  OTP: "OTP",
  SIGNING_REQUEST_APPROVAL: "Signing Request Approval",
  SIGNING_REQUEST_REJECTION: "Signing Request Rejection",
  NEXT: "Next",
  CREATE_ENVIRONMENT: "Create Environment",
  ADD_ENVIRONMENT: "Add Environment",
  SAVE_AND_PROCEED: "Save and Proceed",
  ENVIRONMENT_NAME: "Environment Name",
  FINISH: "Finish",
  REGISTER_USER: "Register User",
  USER_ROLES: "User Roles",
  GENERATE_API_KEY: "Generate API Key",
  EXPIRY_DATE: "Expiry Date",
  API_KEY_NAME: "API Key Name",
  CLOSE: "Close",
  GENERATE: "Generate",
  GENERATE_AUTHENTICATION_CERTIFICATE: "Generate Authentication Certificate",
  VALID_TILL: "Valid Till",
  ROLES: "Roles",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  MODIFY_USER_ROLES: "Modify User Roles",
  MANAGE_TEAM: "Manage Team",
  SIGNING_PROJECT: "Signing Project",
  ID: "ID",
  PROJECT_NAME: "Project Name",
  PROJECT_DESCRIPTION: "Project Description",
  KEY_USAGE: "Reproducible Build Key Usage",
  SOURCE_CODE_MANAGEMENT_TYPE: "Source Code Management",
  GIT_REPOSITORY_URL: "Git Repository URL",
  GIT_ALLOWED_BRANCHES: "Git Allowed Branches",
  GIT_ALLOWED_BRANCH: "Git Allowed Branch",
  GIT_USERNAME: "Git Username",
  GIT_PASSWORD: "Git Password",
  GIT_TOKEN: "Git Token",
  KEY_RENEWAL: "Key Renewal",

  ENVIRONMENTAL_VARIABLES: "Environmental Variables",
  BUILD_COMMAND: "Build Command",
  BUILD_FILE_PATH: "Build File Path",
  BUILD_SIGNER_TYPE: "Build Signer Type",

  BUILD_SERVER_TYPE: "Build Server",
  BUILD_SYSTEM_TYPE: "Build Server",
  JENKINS_PROJECT_URL: "Jenkins Project URL",
  JENKINS_USERNAME: "Jenkins Username",
  JENKINS_PASSWORD: "Jenkins Password",
  EMAIL_RECIPIENTS: "Email Recipient",
  TEAM_NAME: "Team Name",
  Members: "Members",
  EDIT_TEAM: "Edit Team",
  CREATE: "Create",
  UPLOAD: "Upload",
  RENEW: "Renew",

  SCAN_CODE: "Scan Code",
};

export const ModalType = {
  NONE: "none",
  ACTIVATE_KEY: "activateKey",
  DEACTIVATE_KEY: "deactivateKey",
  DOWNLOAD_SELF_SIGNED_CERT: "downloadSelfSignedCert",
  DOWNLOAD_BACKUP: "downloadBackup",
  RESTORE_BACKUP: "restoreBackup",
  ARCHIVE_EMAIL_TEMPLATE: "archiveEmailTemplate",
  RESTORE_EMAIL_TEMPLATE: "restoreEmailTemplate",
  ARCHIVE_EMAIL_CONFIG: "archiveEmailConfig",
  RESTORE_EMAIL_CONFIG: "restoreEmailConfig",
  ARCHIVE_IDENTITY: "archiveIdentity",
  RESTORE_IDENTITY: "restoreIdentity",
  ACTIVATE_APPLICATION: "activateApplication",
  DEACTIVATE_APPLICATION: "deactivateApplication",
  DELETE_APPLICATION_ENVIRONMENT: "deleteApplicationEnvironment",
  TOKEN_GENERATION: "tokenGeneration",
  P12_GENERATION: "p12Generation",
  DELETE_USER: "deleteUser",
  LOCK_USER: "lockUser",
  UNLOCK_USER: "unlockUser",
  USER_ROLES_INFO: "userRolesInfo",
  DELETE_SIGNING_PROJECT: "deleteSigningProject",
  LICENSE: "license",
  MANAGE_TEAM: "manageTeam",
  RENEW_SSL_CERTIFICATE: "renewSSLCertificate",
  DELETE_SSL_CERTIFICATE: "deleteSSLCertificate",
  SSL_FLAG_MODAL: "sslFlagModal",
  SBOM_MODAL: "sbomModal",
};

export const ModalLabels = {
  DOWNLOAD: "Download",
  CANCEL: "Cancel",
  YES: "Yes",
  NO: "No",
};

export const NotificationLabels = {
  KEY_ACTIVATED_SUCCESSFULLY: "Key Activated",
  KEY_ACTIVATED_UNSUCCESSFULLY: "Fail to Activate Key",
  KEY_DEACTIVATED_SUCCESSFULLY: "Key Deactivated",
  KEY_DEACTIVATED_UNSUCCESSFULLY: "Fail to Deactivate Key",
  TRIGGER_UPDATED: "Trigger data Updated Successfully",
  TRIGGER_UPDATE_FAILED: "Fail to update Trigger data",
  UPDATE_SUCCESSFUL: "Update Successful",
  UPDATE_UNSUCCESSFUL: "Update Unsuccessful",
  FORM_SETUP_UNSUCCESSFUL: "Form Setup Unsuccessful",
  CERTIFICATE_CREATED: "Certificate Added Successfully",
  CERTIFICATE_ADDITION_FAILED: "Certificate Addition Failed",
  FILE_DOWNLOADED: "File Downloaded",
  FILE_DOWNLOAD_UNSUCCESSFUL: "File Download Failed",
  OTP_SENT_SUCCESSFUL: "OTP Sent Successfully",
  OTP_SENT_UNSUCCESSFUL: "Failed to send OTP",
  FAILED_TO_GET_APPLICATIONS: "Failed to get application names",
  FAILED_TO_GET_USERS: "Failed to get user names",
  FAILED_TO_GET_SYS_LOG: "Failed to get SysLog",
  SYS_LOG_DOWNLOADED: "Sys Logs Downloaded",
  DONWLOADING_SYS_LOG: "Downloading Sys Log",
  BACKUP_SUCCESSFUL: "Backup taken Successfully",
  FAILED_TO_BACKUP: "Failed to take Backup",
  BACKUP_DOWNLOAD_SUCCESSFUL: "Backup downloaded Successfully",
  BACKUP_DOWNLOAD_UNSUCCESSFUL: "Failed to download backup",
  BACKUP_RESTORED: "Backup restored",
  BACKUP_RESTORE_FAILED: "Failed to restore backup",
  TEMPLATE_ADDED: "Template Added Successfully",
  TEMPLATE_ADDITION_FAILED: "Fail to add Template",
  TEMPLATE_UPDATED: "Template Updated Successfully",
  TEMPLATE_UPDATE_FAILED: "Fail to update Template",
  TEMPLATE_ARCHIVED: "Template Archived",
  TEMPLATE_ARCHIVE_FAILED: "Fail to archive Template",
  TEMPLATE_RESTORED: "Template Restored",
  TEMPLATE_RESTORE_FAILED: "Fail to restore Template",
  CONFIG_ARCHIVED: "Configuration Archived",
  CONFIG_ARCHIVE_FAILED: "Fail to archive Configuration",
  CONFIG_RESTORED: "Configuration Restored",
  CONFIG_RESTORE_FAILED: "Fail to restore Configuration",
  CONFIG_ADDED: "Configuration Added Successfully",
  CONFIG_ADDITION_FAILED: "Fail to add Configuration",
  CONFIG_UPDATED: "Configuration Updated Successfully",
  CONFIG_UPDATE_FAILED: "Fail to update Configuration",
  AD_ADDED: "AD Added Successfully",
  AD_ADDITION_FAILED: "Fail to add AD",
  AD_UPDATED: "AD Updated Successfully",
  AD_UPDATE_FAILED: "Fail to update AD",
  SSO_ADDED: "SSO Added Successfully",
  SSO_ADDITION_FAILED: "Fail to add SSO",
  SSO_UPDATED: "SSO Updated Successfully",
  SSO_UPDATE_FAILED: "Fail to update SSO",
  IDENTITY_ARCHIVED: "Identity Archived",
  IDENTITY_ARCHIVE_FAILED: "Fail to archive Identity",
  IDENTITY_RESTORED: "Identity Restored",
  IDENTITY_RESTORE_FAILED: "Fail to restore Identity",
  SIGNING_REQUEST_APPROVED: "Signing Request Approved",
  SIGNING_REQUEST_APPROVE_FAILED: "Fail to approve Signing Request",
  SIGNING_REQUEST_REJECTED: "Signing Request Rejected",
  SIGNING_REQUEST_REJECT_FAILED: "Fail to reject Signing Request",
  INVALID_OTP: "Invalid OTP",
  APPLICATION_ACTIVATED_SUCCESSFULLY: "Application Activated Successfully",
  APPLICATION_ACTIVATION_FAILED: "Fail to Activate Application",
  APPLICATION_DEACTIVATED_SUCCESSFULLY: "Application Deactivated Successfully",
  APPLICATION_DEACTIVATION_FAILED: "Fail to Deactivate Application",
  ERROR_IN_FETCHING_USERS: "Error in fetching users",
  APPLICATION_ADDED_SUCCESSFULLY: "Application Added",
  APPLICATION_ADDITION_UNSUCCESSFULL: "Failed to Add Application",
  APPLICATION_UPDATED_SUCCESSFULLY: "Application Updated",
  APPLICATION_UPDATE_UNSUCCESSFULL: "Fail to Update Application",
  ENVIRONMENT_ALREADY_EXIST: "Environment Already Exist",
  ENVIRONMENT_CREATED_SUCCESSFULLY: "Environment Created",
  ENVIRONMENT_CREATION_FAILED: "Fail to create Environment",
  TEAM_CREATED_SUCCESSFULLY: "Team Created",
  TEAM_CREATION_FAILED: "Fail to create Team",
  TEAM_UPDATED_SUCCESSFULLY: "Team Updated",
  TEAM_UPDATE_FAILED: "Fail to update Team",
  APPLICATION_ENVIRONMENT_UPDATED: "Application Environments Updated",
  FAIL_TO_UPDATED_APPLICATION_ENVIRONMENT:
    "Fail to Update Application Environments",
  APPLICATION_POLICY_UPDATED: "Application Policies Updated",
  FAIL_TO_UPDATE_APPLICATION_POLICY: "Fail to Update Application Policies",
  FAIL_TO_REGISTER_USER: "Fail to register user",
  USER_REGISTERED_SUCCESSFULLY: "User Registered",
  TOKEN_COPIED: "Token copied to clipboard",
  TOKEN_GENERATED: "Token generated",
  FAIL_TO_GENERATE_TOKEN: "Fail to generate token",
  P12_CERTIFICATE_GENERATED_SUCCESSFULLY: "P12 certificate generated",
  FAIL_TO_GENERATE_P12_CERTIFICATE: "Fail to generate P12 certificate",
  PASSWORD_COPIED: "Password copied to clipboard",
  FAIL_TO_SET_UP_PERMISSION_FORM: "Fail to setup permission form",
  PERMISSIONS_UPDATED: "Permissions Updated",
  FAIL_TO_UPDATE_PERMISSIONS: "Fail to update permissions",
  NOT_AUTHORIZED: "You don't have necessary permissions!",
  USER_DELETED_SUCCESSFULLY: "User Deleted",
  FAILED_TO_DELETE_USER: "Failed to delete user",
  USER_ROLE_UPDATED: "User role updated",
  FAILED_TO_UPDATE_USER_ROLE: "Failed to update user role",
  USER_LOCKED: "User locked",
  FAILED_TO_LOCK_USER: "Failed to lock user",
  USER_UNLOCKED: "User unlocked",
  FAILED_TO_UNLOCK_USER: "Failed to unlock user",
  FAILED_TO_RENEW_LICENSE: "Failed to renew license",
  FAILED_TO_ACTIVATE_LICENSE: "Failed to activate license",
  LICENSE_ACTIVATED: "License Activated",
  LICENSE_RENEWED: "License Renewed",
  SIGNING_PROJECT_SAVED: "Project saved successfully",
  SIGNING_PROJECT_SAVING_FAILED: "Project saving Failed",
  SIGNING_PROJECT_DELETED: "Project deleted successfully",
  CERTIFICATE_DELETED: "Certificate deleted successfully",
  FAILED_TO_DELETE_CERTIFICATE: "Failed to delete certificate",
  SIGNING_PROJECT_DELETION_FAILED: "Project deletion Failed",
  CERTIFICATE_TEAM_UPDATED: "Certificate Team Updated",
  CERTIFICATE_TEAM_UPDATE_FAILED: "Certificate Team Update Failed",
};

export const FileType = {
  ZIP: "application/zip",
  PKCS: "application/x-pkcs12",
  TEXT: "text/plain;charset=utf-8",
};
