import { createSlice } from "@reduxjs/toolkit";
import { Status } from "../../utils/constants";
import { createSelector } from "reselect";
import getSBOMTableDataService from "../../services/sbom/getSBOMTableData";

const initialState = {
  status: Status.Idle,
  error: null,
  tableData: [],
};

export const sbomSlice = createSlice({
  name: "sbom",
  initialState,
  reducers: {
    resetState: (state) => {
      state.status = Status.Idle;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSBOMTableDataService.pending, (state) => {
        state.status = Status.Loading;
        state.error = null;
      })
      .addCase(getSBOMTableDataService.fulfilled, (state, action) => {
        state.tableData = action.payload;
        state.status = Status.Succeeded;
        state.error = null;
      })
      .addCase(getSBOMTableDataService.rejected, (state, action) => {
        if (action.error.name === "CanceledError") {
          return;
        } else {
          state.tableData = [];
          state.status = Status.Failed;
          state.error = action.payload ?? "something went wrong";
        }
      });
  },
});

export const selectSBOMTableData = createSelector(
  [
    (state) => state.sbom.status,
    (state) => state.sbom.error,
    (state) => state.sbom.tableData,
  ],

  (status, error, tableData) => ({
    tableData,
    status,
    error,
  })
);

export const selectSBOMDataByID = createSelector(
  [
    (state) => state.sbom.tableData,
    (state) => state.sbom.status,
    (state) => state.sbom.error,
    (_, id) => id,
  ],
  (tableData, status, error, id) => {
    if (status === Status.Failed) {
      return {
        isTableDataFetched: false,
        error: error,
        selectedSBOMData: null,
      };
    } else if (tableData?.length > 0) {
      let selectedSBOMData = tableData.filter((data) => data.id === id);

      if (selectedSBOMData.length > 0) {
        return {
          isTableDataFetched: true,
          error: null,
          selectedSBOMData: selectedSBOMData[0],
        };
      } else {
        return {
          isTableDataFetched: true,
          error: null,
          selectedSBOMData: null,
        };
      }
    } else if (status === Status.Succeeded && tableData?.length === 0) {
      return {
        isTableDataFetched: true,
        error: null,
        selectedSBOMData: null,
      };
    } else {
      return {
        isTableDataFetched: false,
        error: null,
        selectedSBOMData: null,
      };
    }
  }
);

export const { resetState } = sbomSlice.actions;

export default sbomSlice.reducer;
