// React
import { useEffect, useState } from "react";

// React-Router
import { NavLink } from "react-router-dom";

// Mui
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// Icons
import DashboardSideBar from "../icons/DashboardSideBar";
import KeyCertSideBar from "../icons/keyCertSideBar";
import LoggingSideBar from "../icons/LoggingSideBar";
import ReportSideBar from "../icons/ReportSideBar";
import ECLogo from "../icons/ECLogo";
import SigningRequestSideBar from "../icons/SigningRequestSideBar";
import SystemSetupSideBar from "../icons/SystemSetupSideBar";
import SigningToolsSideBar from "../icons/SigningToolsSideBar";
import AppManageSideBar from "../icons/AppManageSideBar";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import { useSelector } from "react-redux";

// This variable decide the size of the icons in the asideBar links (in px)
const iconWidth = 25;

function DrawerHeader({ isSideBarExpanded, theme, setIsSideBarExpanded }) {
  return (
    <Box
      sx={{
        height: 70,
        bgcolor: "primary.main",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
        position: "relative",
        boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.25)",
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          // ml: isSideBarExpanded ? 0 : -2,
          display: "grid",
          placeContent: "center",
          width: 40,
        }}
      >
        <ECLogo fill="white" width="100%" />
      </Box>
      <Typography
        variant="h6"
        sx={{
          lineHeight: 1,
          fontSize: "0.9rem",
          fontWeight: 700,
          display: isSideBarExpanded ? "block" : "none",
          color: "primary.contrastText",
        }}
      >
        ENCRYPTION
        <br />
        CONSULTING
      </Typography>
      <Box
        sx={{
          display: "grid",
          placeContent: "center",
          width: 25,
          height: 25,
          bgcolor: "tertiary.main",
          borderRadius: "50%",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          right: "-12.5px",
        }}
      >
        <IconButton
          onClick={() => {
            setIsSideBarExpanded((prev) => !prev);
          }}
          sx={{
            transform: `rotate(${isSideBarExpanded ? 180 : 0}deg)`,
            transition: "all linear 0.4s",
          }}
        >
          <ChevronRightIcon
            sx={{ stroke: theme.palette.primary.main, strokeWidth: 1 }}
          />
        </IconButton>
      </Box>
    </Box>
  );
}

function DrawerLinks({ isSideBarExpanded, linksArr }) {
  return (
    <List sx={{ py: 3, height: 1, overflowY: "auto" }}>
      {linksArr.map(({ linkText, linkIcon, url }, index) => (
        <ListItem
          key={index}
          component={NavLink}
          to={url}
          disablePadding
          sx={{
            mb: 1,
            display: "block",
            "&.active": {
              bgcolor: "secondary.dark",
              pointerEvents: "none",
            },
            "&:hover": {
              bgcolor: "secondary.dark",
              opacity: 0.8,
            },
            "&:focus": {
              bgcolor: "secondary.dark",
            },
          }}
        >
          <ListItemButton
            sx={{
              height: 55,
              overflow: "hidden",
              justifyContent: isSideBarExpanded ? "initial" : "center",
              px: isSideBarExpanded ? 4 : 0,
              "& > .MuiListItemIcon-root": {
                width: isSideBarExpanded ? "fit-content" : "100%",
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isSideBarExpanded ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {linkIcon}
            </ListItemIcon>
            <ListItemText
              primary={linkText}
              sx={{
                display: isSideBarExpanded ? "block" : "none",
                whiteSpace: "nowrap",
                color: "primary.contrastText",
                "& > .MuiTypography-root": {
                  fontSize: "1rem",
                  fontWeight: 600,
                },
              }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

export default function AsideBar() {
  const theme = useTheme();

  const [linksArr, setLinksArr] = useState([]);
  const [isSideBarExpanded, setIsSideBarExpanded] = useState(false);

  const userPermissions = useSelector((state) => state.auth.userPermissions);

  function getLinksArr() {
    let arr = [];

    if (checkUserPermission(Permissions.ViewDashboard)) {
      arr.push({
        linkText: "Dashboard",
        linkIcon: <DashboardSideBar fill={"white"} width={iconWidth} />,
        url: "/",
      });
    }

    if (checkUserPermission(Permissions.ViewKeysAndCertificates)) {
      arr.push({
        linkText: "Keys and Certificates",
        linkIcon: <KeyCertSideBar fill={"white"} width={iconWidth} />,
        url: "keys-and-certificates",
      });
    }

    if (checkUserPermission(Permissions.ViewApplicationManagement)) {
      arr.push({
        linkText: "Application Management",
        linkIcon: <AppManageSideBar fill={"white"} width={iconWidth} />,
        url: "application-management",
      });
    }

    if (checkUserPermission(Permissions.ViewSigningRequest)) {
      arr.push({
        linkText: "Signing Request",
        linkIcon: <SigningRequestSideBar fill={"white"} width={iconWidth} />,
        url: "signing-request",
      });
    }

    if (checkUserPermission(Permissions.ViewSigningTools)) {
      arr.push({
        linkText: "Signing Tools",
        linkIcon: <SigningToolsSideBar fill={"white"} width={iconWidth} />,
        url: "signing-tools",
      });
    }

    if (checkUserPermission(Permissions.ViewReports)) {
      arr.push({
        linkText: "Reports",
        linkIcon: <ReportSideBar fill={"white"} width={iconWidth} />,
        url: "reports",
      });
    }

    if (checkUserPermission(Permissions.ViewLogs)) {
      arr.push({
        linkText: "Logs",
        linkIcon: <LoggingSideBar fill={"white"} width={iconWidth} />,
        url: "logs",
      });
    }

    if (
      checkUserPermission(Permissions.ViewSystemSetup) ||
      checkUserPermission(Permissions.ViewSystemSetupEmail) ||
      checkUserPermission(Permissions.ViewSystemSetupUserManagement) ||
      checkUserPermission(Permissions.ViewSystemSetupUserPermissions) ||
      checkUserPermission(Permissions.ViewSystemSetupBackup) ||
      checkUserPermission(Permissions.ViewSystemSetupSBOM) ||
      checkUserPermission(Permissions.ViewSystemSetupSigningProjects) ||
      checkUserPermission(Permissions.ViewSystemSetupSSLCertificate)
    ) {
      arr.push({
        linkText: "System Setup",
        linkIcon: <SystemSetupSideBar fill={"white"} width={iconWidth} />,
        url: "system-setup",
      });
    }

    return arr;
  }

  useEffect(() => {
    setLinksArr(getLinksArr());
  }, [userPermissions]);

  return (
    <Drawer
      variant="permanent"
      open={isSideBarExpanded}
      sx={{
        width: isSideBarExpanded ? 300 : 90,
        transition: "width ease-in-out 0.3s",

        "& > .MuiPaper-root": {
          width: "inherit",
          borderRight: "none",
          bgcolor: "primary.main",
          overflow: "visible",
        },
      }}
    >
      <DrawerHeader
        isSideBarExpanded={isSideBarExpanded}
        setIsSideBarExpanded={setIsSideBarExpanded}
        theme={theme}
      />
      <DrawerLinks isSideBarExpanded={isSideBarExpanded} linksArr={linksArr} />
    </Drawer>
  );
}
