import { closeForm } from "../../features/forms/formSlice";
import { showLoader, closeLoader } from "../../features/loader/loaderSlice";
import Api from "../../lib/axios/api";
import { store } from "../../lib/redux/store";
import Notification from "../../utils/notificationConfiguration";
import URL from "../../utils/url";
import getSBOMTableDataService from "./getSBOMTableData";
import { initializeModal } from "../../features/modals/modalSlice";
import { ModalType } from "../../utils/constants";

export default async function scanCodeService(formData) {
  try {
    store.dispatch(showLoader());

    let reqBody = new FormData();

    reqBody.append("github_url", formData.gitRepositoryURL);
    reqBody.append("github_username", formData.gitUserName);
    reqBody.append("github_password", formData.gitToken);
    reqBody.append("github_email", formData.gitEmail);
    reqBody.append(
      "vulnerability_percentage",
      formData.vulnerabilityPercentage
    );
    reqBody.append("source_code_zip", formData.sourceCode);
    reqBody.append("allowed_branch", formData.gitAllowedBranch);

    const response = await Api.post(URL.SBOM.ScanCode, reqBody, {
      headers: { "Content-type": "multipart/form-data" },
    });

    store.dispatch(
      initializeModal({
        modalType: ModalType.SBOM_MODAL,
        modalData: {
          status: response.data?.status ?? "",
          message: response.data?.message ?? "",
          log: response.data?.log ?? "",
        },
      })
    );

    store.dispatch(getSBOMTableDataService());

    Notification.success("Code Scanned Successfully");
    store.dispatch(closeForm());
  } catch (error) {
    let errorMessage = error?.response?.data?.error ?? null;
    Notification.error(errorMessage ?? "Failed to scan code");
  } finally {
    store.dispatch(closeLoader());
  }
}
