// React
import { useRef, useEffect } from "react";

// MUI
import Button from "@mui/material/Button";

// Redux
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

// Constants
import { Status, Labels } from "../../utils/constants";

// Custom Components
import CustomTable from "../../components/ui/table/CustomTable";
import { Stack } from "@mui/material";
import getBackupTableDataService from "../../services/backup/getBackupTableData";
import { formatDateToUserTimeZone } from "../../utils/dateFormatters";
import downloadBackup from "../../services/backup/downloadBackup";
import { initializeModal } from "../modals/modalSlice";
import { ModalType } from "../../utils/constants";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { useErrorBoundary } from "react-error-boundary";
import checkUserPermission from "../../utils/checkUserPermission";
import { Permissions } from "../../utils/enums";
import Typography from "@mui/material/Typography";
import { selectSBOMTableData } from "./SBOMSlice";
import getSBOMTableDataService from "../../services/sbom/getSBOMTableData";

function LoadingSkeleton() {
  return (
    <Box
      sx={{
        mt: 3,
        width: 1,
        height: "calc(100% - 70px)",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: "70px",
          borderRadius: "5px",
        }}
      />

      <Skeleton
        variant="rectangular"
        sx={{
          width: 1,
          height: 1,
          borderRadius: "5px",
        }}
      />
    </Box>
  );
}

export default function SBOMTable() {
  const { showBoundary } = useErrorBoundary();

  const dispatch = useDispatch();

  const abortControllerRef = useRef(null);

  const { tableData, status, error } = useSelector(selectSBOMTableData);

  useEffect(() => {
    if (status === Status.Idle) {
      abortControllerRef.current = new AbortController();

      dispatch(
        getSBOMTableDataService({
          abortController: abortControllerRef.current,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === Status.Failed) {
    showBoundary(error);
  }

  if (tableData.length === 0 && status !== Status.Succeeded) {
    return <LoadingSkeleton />;
  }

  return <TableComponent tableData={tableData} />;
}

function TableComponent({ tableData }) {
  const isUserAllowedToScan = checkUserPermission(Permissions.SBOMScan);

  const rowData = tableData;

  const columnData = [
    {
      field: "id",
      headerName: Labels.UID,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => renderUIDCell(params.row),
    },
    {
      field: "githubURL",
      headerName: Labels.GIT_REPOSITORY_URL,
      flex: 1,
      minWidth: 250,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "githubUsername",
      headerName: Labels.GIT_USERNAME,
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value;
      },
    },
    {
      field: "vulnerabilityPercentage",
      headerName: Labels.VULNERABILITY_PERCENTAGE,
      minWidth: 200,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "-";
        }
        return params.value + "%";
      },
    },
    {
      field: "status",
      headerName: Labels.STATUS,
      minWidth: 150,
      renderCell: (params) => renderStatusCell(params.value),
    },
    {
      field: "submissionDate",
      headerName: Labels.SUBMITTED_ON,
      minWidth: 250,
      filterable: false,
      valueFormatter: (params) => {
        if (!params.value) {
          return "-";
        }
        return formatDateToUserTimeZone(params.value);
      },
    },
  ];

  function renderUIDCell(data) {
    if (data?.id) {
      return (
        <Box
          sx={{
            mr: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "#1e90ff",
          }}
        >
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={`detail/${data.id}`}
          >
            {data.id}
          </Link>
        </Box>
      );
    } else {
      return <span>-</span>;
    }
  }

  function renderStatusCell(status) {
    let cellText = "";

    if (status === "success") {
      cellText = "Success";
    } else if (status === "warning") {
      cellText = "Warning";
    }

    function getBGColorForCell(cellText) {
      switch (cellText) {
        case "Success": {
          return "success.main";
        }
        case "Warning": {
          return "error.main";
        }
        default: {
          return "";
        }
      }
    }

    return (
      <Box
        sx={{
          minWidth: "90px",
          textAlign: "center",
          paddingBlock: 0.75,
          color: "primary.main",
          borderRadius: "5px",
          backgroundColor: getBGColorForCell(cellText),
        }}
      >
        {cellText}
      </Box>
    );
  }

  return (
    <CustomTable
      showTableOptions={true}
      rowData={rowData}
      columnData={columnData}
      valueToBeUsedAsID="id"
      sortingField="submittedOn"
      {...(!isUserAllowedToScan && { tableHeight: "100%" })}
    />
  );
}
