// React
import { useEffect, useState } from "react";

// React Router
import { useLocation } from "react-router-dom";

// MUI
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Constants
import { BackendSettings, ModalType } from "../../utils/constants";

// Icons
import NavBarAPI from "../icons/NavBarAPI";

import InfoIcon from "../icons/InfoIcon";
import HyperLinkArrowIcon from "../icons/HyperLinkArrowIcon";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import LogoutIcon from "../icons/LogoutIcon";

import { Divider, Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  initiateLogout,
  selectAuthenticatedUserData,
} from "../../features/auth/authSlice";
import { Link as RouterLink } from "react-router-dom";
import { store } from "../../lib/redux/store";
import DocIcon from "../icons/DocIcon";
import { initializeModal } from "../../features/modals/modalSlice";
import { isCurrentTimeGreaterThenProvidedTime } from "../../utils/dateFormatters";
import Notification from "../../utils/notificationConfiguration";

function CustomNavLink({ components }) {
  return (
    <Breadcrumbs
      separator="/"
      sx={{
        fontSize: "1.25rem",
        fontWeight: 700,
        color: "black",
      }}
    >
      {components.map((component, index) => {
        if (component.type === "link") {
          return (
            <Link key={index} underline="hover" color="inherit">
              <RouterLink
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
                to={component.url}
              >
                {component.label}
              </RouterLink>
            </Link>
          );
        } else {
          return (
            <Link key={index} underline="none" color="inherit">
              {component.label}
            </Link>
          );
        }
      })}
    </Breadcrumbs>
  );
}

function NavLabel() {
  const { pathname } = useLocation();

  function getLinks() {
    switch (true) {
      case pathname === "/": {
        return (
          <CustomNavLink
            components={[
              {
                type: "text",
                label: "Dashboard",
              },
            ]}
          />
        );
      }
      case pathname.includes("/keys-and-certificates"): {
        if (pathname.includes("detail")) {
          return (
            <CustomNavLink
              components={[
                {
                  type: "link",
                  url: "/keys-and-certificates",
                  label: "Keys And Certificates",
                },
                {
                  type: "text",
                  label: "Detail",
                },
              ]}
            />
          );
        } else {
          return (
            <CustomNavLink
              components={[
                {
                  type: "text",
                  label: "Keys And Certificates",
                },
              ]}
            />
          );
        }
      }
      case pathname.includes("/application-management"): {
        if (pathname.includes("new")) {
          return (
            <CustomNavLink
              components={[
                {
                  type: "link",
                  url: "/application-management",
                  label: "Application Management",
                },
                {
                  type: "text",
                  label: "New",
                },
              ]}
            />
          );
        } else if (pathname.includes("detail")) {
          return (
            <CustomNavLink
              components={[
                {
                  type: "link",
                  url: "/application-management",
                  label: "Application Management",
                },
                {
                  type: "text",
                  label: "Detail",
                },
              ]}
            />
          );
        } else if (pathname.includes("/application-management/")) {
          return (
            <CustomNavLink
              components={[
                {
                  type: "link",
                  url: "/application-management",
                  label: "Application Management",
                },
                {
                  type: "text",
                  label: "Edit",
                },
              ]}
            />
          );
        } else {
          return (
            <CustomNavLink
              components={[
                {
                  type: "text",
                  label: "Application Management",
                },
              ]}
            />
          );
        }
      }
      case pathname.includes("/signing-request"): {
        if (pathname.includes("detail")) {
          return (
            <CustomNavLink
              components={[
                {
                  type: "link",
                  url: "/signing-request",
                  label: "Signing Request",
                },
                {
                  type: "text",
                  label: "Detail",
                },
              ]}
            />
          );
        } else {
          return (
            <CustomNavLink
              components={[
                {
                  type: "text",
                  label: "Signing Request",
                },
              ]}
            />
          );
        }
      }
      case pathname.includes("/signing-tools"): {
        return (
          <CustomNavLink
            components={[
              {
                type: "text",
                label: "Signing Tools",
              },
            ]}
          />
        );
      }
      case pathname.includes("/reports"): {
        if (pathname.includes("detail")) {
          return (
            <CustomNavLink
              components={[
                {
                  type: "link",
                  url: "/reports",
                  label: "Reports",
                },
                {
                  type: "text",
                  label: "Detail",
                },
              ]}
            />
          );
        } else {
          return (
            <CustomNavLink
              components={[
                {
                  type: "text",
                  label: "Reports",
                },
              ]}
            />
          );
        }
      }
      case pathname.includes("/logs"): {
        if (pathname.includes("detail")) {
          return (
            <CustomNavLink
              components={[
                {
                  type: "link",
                  url: "/logs",
                  label: "Logs",
                },
                {
                  type: "text",
                  label: "Detail",
                },
              ]}
            />
          );
        } else {
          return (
            <CustomNavLink
              components={[
                {
                  type: "text",
                  label: "Logs",
                },
              ]}
            />
          );
        }
      }
      case pathname.includes("/system-setup"): {
        switch (true) {
          case pathname.includes("/system-setup/backup"): {
            return (
              <CustomNavLink
                components={[
                  {
                    type: "text",
                    label: "Backup",
                  },
                ]}
              />
            );
          }
          case pathname.includes("/system-setup/user/management"): {
            if (pathname.includes("detail")) {
              return (
                <CustomNavLink
                  components={[
                    {
                      type: "link",
                      url: "/system-setup/user/management",
                      label: "User Management",
                    },
                    {
                      type: "text",
                      label: "Detail",
                    },
                  ]}
                />
              );
            } else {
              return (
                <CustomNavLink
                  components={[
                    {
                      type: "text",
                      label: "User Management",
                    },
                  ]}
                />
              );
            }
          }
          case pathname.includes("/system-setup/user/permissions"): {
            return (
              <CustomNavLink
                components={[
                  {
                    type: "text",
                    label: "User Permissions",
                  },
                ]}
              />
            );
          }
          case pathname.includes("/system-setup/crypto"): {
            return (
              <CustomNavLink
                components={[
                  {
                    type: "text",
                    label: "Crypto",
                  },
                ]}
              />
            );
          }
          case pathname.includes("/system-setup/client"): {
            return (
              <CustomNavLink
                components={[
                  {
                    type: "text",
                    label: "Client",
                  },
                ]}
              />
            );
          }
          case pathname.includes("/system-setup/identity-store"): {
            if (pathname.includes("detail")) {
              return (
                <CustomNavLink
                  components={[
                    {
                      type: "link",
                      url: "/system-setup/identity-store",
                      label: "Identity Store",
                    },
                    {
                      type: "text",
                      label: "Detail",
                    },
                  ]}
                />
              );
            } else {
              return (
                <CustomNavLink
                  components={[
                    {
                      type: "text",
                      label: "Identity Store",
                    },
                  ]}
                />
              );
            }
          }
          case pathname.includes("/system-setup/signing-projects"): {
            if (pathname.includes("detail")) {
              return (
                <CustomNavLink
                  components={[
                    {
                      type: "link",
                      url: "/system-setup/signing-projects",
                      label: "Signing Projects",
                    },
                    {
                      type: "text",
                      label: "Detail",
                    },
                  ]}
                />
              );
            }
            return (
              <CustomNavLink
                components={[
                  {
                    type: "text",
                    label: "Signing Projects",
                  },
                ]}
              />
            );
          }
          case pathname.includes("/system-setup/email/template"): {
            return (
              <CustomNavLink
                components={[
                  {
                    type: "text",
                    label: "Email Template",
                  },
                ]}
              />
            );
          }
          case pathname.includes("/system-setup/email/config"): {
            if (pathname.includes("detail")) {
              return (
                <CustomNavLink
                  components={[
                    {
                      type: "link",
                      url: "/system-setup/email/config",
                      label: "Email Config",
                    },
                    {
                      type: "text",
                      label: "Detail",
                    },
                  ]}
                />
              );
            } else {
              return (
                <CustomNavLink
                  components={[
                    {
                      type: "text",
                      label: "Email Config",
                    },
                  ]}
                />
              );
            }
          }
          case pathname.includes("/system-setup/ssl-tls-management"): {
            if (pathname.includes("detail")) {
              return (
                <CustomNavLink
                  components={[
                    {
                      type: "link",
                      url: "/system-setup/ssl-tls-management/",
                      label: "SSL/TLS Management",
                    },
                    {
                      type: "text",
                      label: "Detail",
                    },
                  ]}
                />
              );
            } else {
              return (
                <CustomNavLink
                  components={[
                    {
                      type: "text",
                      label: "SSL/TLS Management",
                    },
                  ]}
                />
              );
            }
          }
          case pathname.includes("/system-setup/sbom"): {
            if (pathname.includes("detail")) {
              return (
                <CustomNavLink
                  components={[
                    {
                      type: "link",
                      url: "/system-setup/sbom/",
                      label: "SBOM",
                    },
                    {
                      type: "text",
                      label: "Detail",
                    },
                  ]}
                />
              );
            } else {
              return (
                <CustomNavLink
                  components={[
                    {
                      type: "text",
                      label: "SBOM",
                    },
                  ]}
                />
              );
            }
          }
          default: {
            return (
              <CustomNavLink
                components={[
                  {
                    type: "text",
                    label: "404",
                  },
                ]}
              />
            );
          }
        }
      }
      default: {
        return (
          <CustomNavLink
            components={[
              {
                type: "text",
                label: "404",
              },
            ]}
          />
        );
      }
    }
  }

  return getLinks();
}

function UserMenuIcon({ width = 26 }) {
  let userNotation = store.getState().auth.userName.charAt(0).toUpperCase();

  return (
    <Box
      sx={{
        borderRadius: "50px",
        width: width,
        height: width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "secondary.dark",
      }}
    >
      <Typography
        variant="h6"
        color="secondary.contrastText"
        sx={{
          fontFamily: `"Lora", serif`,
          fontSize: `${width <= 26 ? "1.2rem" : "1.4rem"}`,
          fontWeight: 800,
          lineHeight: 1,
        }}
      >
        {userNotation}
      </Typography>
    </Box>
  );
}

function UserMenu({
  userMenuAnchor,
  setUserMenuAnchor,
  userData,
  logoutHandler,
  theme,
  dispatch,
}) {
  return (
    <Menu
      anchorEl={userMenuAnchor}
      onClose={() => setUserMenuAnchor(null)}
      open={userMenuAnchor ? true : false}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        "& > .MuiPaper-root": {
          borderRadius: "5px",
          fontSize: "0.9rem",
          minWidth: 250,

          hr: {
            borderColor: "#e6e6e6",
          },

          li: {
            fontSize: "inherit",
            py: 0.75,
            px: 2,
          },
        },
      }}
    >
      {/* UserName */}
      <MenuItem
        sx={{
          pointerEvents: "none",
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "baseline",
        }}
      >
        <UserMenuIcon width={30} />

        <Typography variant="h6" sx={{ fontSize: "1.2rem", fontWeight: 800 }}>
          {userData.userName.charAt(0).toUpperCase() +
            userData.userName.slice(1)}
        </Typography>
      </MenuItem>

      <Divider />

      {/* User Role */}
      <MenuItem
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1.25,
          alignItems: "center",
          flexWrap: "wrap",
          rowGap: 0,
        }}
        onClick={() => {
          setUserMenuAnchor(null);
          dispatch(
            initializeModal({
              modalType: ModalType.USER_ROLES_INFO,
            })
          );
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "0.9rem", fontWeight: 700 }}
        >
          User Roles
        </Typography>

        <Typography variant="h6" sx={{ fontSize: "0.9rem", fontWeight: 700 }}>
          :
        </Typography>

        <Stack
          direction="row"
          sx={{
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "0.8rem",
              px: "10px",
              py: "2px",
              backgroundColor: "#efebeb",
              borderRadius: "5px",
              fontWeight: 700,
              ml: 0.25,
            }}
          >
            {userData.userRoles[0].label}
          </Typography>

          <IconButton sx={{ p: 0 }}>
            <InfoIcon width={18} fill={theme.palette.secondary.dark} />
          </IconButton>
        </Stack>
      </MenuItem>

      <Divider />

      {/* Version */}
      <Tooltip
        title={
          <Stack
            sx={{ py: "5px", px: "10px", flexDirection: "column", gap: 0.5 }}
          >
            <Typography
              variant="h6"
              sx={{ fontSize: "0.8rem", fontWeight: 700 }}
            >
              Backend : {userData?.backendVersion ?? "-"}
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "0.8rem", fontWeight: 700 }}
            >
              Frontend : {userData?.frontendVersion ?? "-"}
            </Typography>
          </Stack>
        }
        arrow
        placement="left"
      >
        <Box
          sx={{
            px: "16px",
            py: "5px",
            display: "flex",
            flexDirection: "row",
            gap: 1.5,
            justifyContent: "space-between",
            alignItems: "center",
            ".MuiButtonBase-root": {
              p: 0,
            },
          }}
        >
          <Typography variant="h6" sx={{ fontSize: "0.9rem", fontWeight: 700 }}>
            Version : {userData?.backendVersion ?? "-"}
          </Typography>

          <IconButton>
            <InfoIcon width={18} fill={theme.palette.secondary.dark} />
          </IconButton>
        </Box>
      </Tooltip>

      <Divider sx={{ my: "8px" }} />

      {/* License */}
      <MenuItem
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1.5,
          justifyContent: "space-between",
          alignItems: "center",
          py: "5px !important",
          ".MuiButtonBase-root": {
            p: 0,
          },
        }}
        onClick={() => {
          setUserMenuAnchor(null);
          dispatch(
            initializeModal({
              modalType: ModalType.LICENSE,
            })
          );
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "0.9rem", fontWeight: 700 }}>
          License
        </Typography>

        <IconButton sx={{ p: 0 }}>
          <InfoIcon width={18} fill={theme.palette.secondary.dark} />
        </IconButton>
      </MenuItem>

      <Divider />

      {/* help */}
      <Box
        sx={{
          px: "16px",
          py: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontSize: "0.9rem", fontWeight: 700, mb: 1 }}
        >
          Help & Support
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "0.9rem", fontWeight: 500, mb: 0.25 }}
        >
          info@encryptionconsutling.com
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "0.9rem", fontWeight: 500 }}
        >
          +1- 469-815-4136
        </Typography>

        {/* <Stack sx={{ flexDirection: "row", width: 1, position: "relative" }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "0.9rem",
              fontWeight: 700,
              lineHeight: "100%",
            }}
          >
            Email :
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              lineHeight: "100%",
              a: {
                position: "absolute",
                ml: 1,
                left: 45,
                right: 0,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "0.9rem",
              },
            }}
          >
            <a href="mailto:info@encryptionconsutling.com">
              info@encryptionconsutling.com
            </a>
          </Typography>
        </Stack>

        <Stack sx={{ flexDirection: "row", width: 1 }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "0.9rem",
              fontWeight: 700,
              lineHeight: "100%",
            }}
          >
            Call Us :
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              lineHeight: "100%",
              a: {
                display: "inline-block",
                ml: 1,
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "0.9rem",
              },
            }}
          >
            <a href="tel:+14698154136"> +1- 469-815-4136</a>
          </Typography>
        </Stack> */}
      </Box>

      <Divider sx={{ my: "8px" }} />

      {/* logout */}
      <MenuItem
        onClick={() => logoutHandler()}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1.5,
          justifyContent: "space-between",
          alignItems: "center",
          py: "5px !important",
          ".MuiButtonBase-root": {
            p: 0,
          },
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "0.9rem", fontWeight: 700 }}>
          Logout
        </Typography>

        <IconButton>
          <LogoutIcon width={18} fill={theme.palette.secondary.dark} />
        </IconButton>
      </MenuItem>
    </Menu>
  );
}

export default function NavBar() {
  const dispatch = useDispatch();

  useEffect(() => {
    let expiringOn = store.getState().license?.licenseInfo?.expiresOn ?? null;

    if (expiringOn) {
      let differenceMs = new Date(expiringOn).getTime() - new Date().getTime();
      let daysRemaining = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

      if (daysRemaining <= 30) {
        Notification.warning(
          `The license is going to expire in ${daysRemaining} days. Please renew it.`
        );
      }
    }
  }, []);

  function logoutHandler() {
    dispatch(initiateLogout());
  }

  const userData = useSelector(selectAuthenticatedUserData);

  const theme = useTheme();

  const [userMenuAnchor, setUserMenuAnchor] = useState(null);

  return (
    <Stack
      direction={"row"}
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        width: 1,
        px: 5,
        height: 70,
        bgcolor: "background.paper",
        boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.25)",
      }}
    >
      <NavLabel />

      <Stack direction={"row"} sx={{ alignItems: "center", gap: 1 }}>
        <RouterLink
          to={`${BackendSettings.BaseURL}documentation`}
          target="_blank"
        >
          <IconButton
            sx={{
              "&:hover": {
                opacity: 0.9,
              },
            }}
          >
            <DocIcon width={22} fill={theme.palette.secondary.dark} />
          </IconButton>
        </RouterLink>

        <RouterLink to={`${BackendSettings.BaseURL}swagger/`} target="_blank">
          <IconButton
            sx={{
              "&:hover": {
                opacity: 0.9,
              },
            }}
          >
            <NavBarAPI width={25} fill={theme.palette.secondary.dark} />
          </IconButton>
        </RouterLink>

        <IconButton
          onClick={(event) => setUserMenuAnchor(event.currentTarget)}
          sx={{
            "&:hover": {
              opacity: 0.9,
            },
          }}
        >
          {/* <UserIcon width={25} fill={theme.palette.secondary.dark} /> */}
          <UserMenuIcon />
        </IconButton>
      </Stack>

      <UserMenu
        userMenuAnchor={userMenuAnchor}
        setUserMenuAnchor={setUserMenuAnchor}
        userData={userData}
        logoutHandler={logoutHandler}
        theme={theme}
        dispatch={dispatch}
      />
    </Stack>
  );
}
